import React from "react";
import { useEffect, useRef, memo } from 'react';
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";
import HeadingText from "./HeadingText.js";
import OurSchoolClients from "../clients/Schools.js";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TradingViewWidget from "components/features/StockHeatmap.js";

const Container = tw.div`relative lg:px-0 xs:px-2 pt-8 md:pt-0 lg:pt-0`;
const TwoColumn = tw.div`flex flex-col mt-0 md:mt-20 lg:mt-12 lg:flex-row lg:items-center max-w-screen-xl mx-auto xs:py-10 md:py-6`;
const LeftColumn = tw.div`relative w-full  text-center  mx-auto  lg:text-left`;
const RightColumn = tw.div`w-full relative order-first md:order-first lg:order-last mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-bold text-xl mt-0 md:mt-8 lg:mt-0 lg:pt-0 md:text-4xl lg:text-4xl xl:text-3xl text-black leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-left px-6 lg:pl-0 lg:pr-6`;

const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0`}
  button {
    ${tw`w-8/12 bg-secondary-600 text-gray-100 xs:m-auto lg:ml-0 lg:mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-secondary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const ClientSection = tw.section`text-center mt-5 mb-10`;
const UpcomingBlock = tw.div`flex items-center py-4 space-x-6`;
const BatchHead = tw.h3`text-left text-xl text-blue-700 font-bold `;
const BatchLink = tw.a`text-center px-4 py-2 bg-[#ccdff2] mr-2 text-blue-700 rounded-sm font-semibold`;

const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`text-blue-700 font-bold !text-lg inline-block mx-auto md:mx-0 rounded-full`
]);
const Content = tw.p`text-blue-700 font-bold !text-lg inline-block mx-auto md:mx-0 rounded-full`;
const Highlight = tw.span`text-left text-base xl:text-lg text-[#dc3188] font-bold px-2`;


export default ({ 
  roundedHeaderButton,
  mainTitle='',
  subTitle='',
  imageSrc='',
  buttonText='',
  buttonUrl='https://wa.me/918920403902/?text=Hi, How can i GROW my Digital Content Business with Aurum', 
  showClient= true,
  text,
  knowmoreUrl,

  
}) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            {mainTitle && (
              <Heading>
              {mainTitle}
              </Heading>
            )}
            {subTitle && (
              <Paragraph style={{textAlign:"justify"}} dangerouslySetInnerHTML={{ __html:subTitle}}>
             
              </Paragraph>
            )}
            <UpcomingBlock>
            {/* {knowmoreUrl &&(
              <LinkButton  className="exploreBtn" as="a" href={knowmoreUrl}>
                <Content>Click Here - to Know More About <Highlight> Algo Trading Workshop</Highlight></Content>
              </LinkButton>
            )} */}
            </UpcomingBlock>
            <Actions>
              <a  href={buttonUrl} target="_blank"><button className="bt-round">{buttonText}</button></a>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <TradingViewWidget />
          </RightColumn>
        </TwoColumn>
        {showClient == true && (
          <>
            <HeadingText />
            <ClientSection>
              <OurSchoolClients />
            </ClientSection>
          </>
        )}
      </Container>
    </>
  );
};
