import React from "react";
import tw from "twin.macro";

const Container = tw.div`text-center`;
const Heading = tw.h2`text-black`;

export default ({
 	
  	heading = "Trusted By Leading Schools",
  	
	}) => {
  return (
    <Container>
        <Heading className="extraBold rotate-wrapper">
        	<span className='rw-opener'>{heading}</span>
       	</Heading>
    </Container>
  );
};