import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`bg-[#204dcf] py-8 text-textBlack -mx-8 px-8 xs:px-10`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl text-white`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`font-medium text-white text-base leading-loose text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium text-white`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black text-white`
const Lang1 = tw.span`font-bold text-black leading-tight text-[#231f66]`;
const Panth1 = tw.span`font-bold text-black leading-tight text-[#2fb3c0]`;
export default ({
  subheading = "",
  heading = "Happyness of Second Income with Transparency, Conviction & Consistency ",
  description = "Empower Your Small Savings with Precision: Rule-Based Trading Redefined",
  stats = [
    {
      key: "Strategies",
      value: "100+",
    },
    {
      key: "Engaged Users",
      value: "1K+",
    },
    {
      key: "Team",
      value: "20+",
    },
  ]
}) => {
  return (
    <Container>
     
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
     
    </Container>
  );
};
