import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import ComponentRenderer from "ComponentRenderer.js";
import Home from "MainPage.js";

import ThankYouPage from "ThankYouPage.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import tawkTo from "tawkto-react";


export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  const tawkToPropertyId = '64538ccb31ebfa0fe7fbedf0';

  const tawkToKey = '1gvj5rvt5';

  React.useEffect(() => {
   tawkTo(tawkToPropertyId, tawkToKey);
   //alert ("1");
  }, [])
  
  return (
    <Router basename={'/'}>
      <Switch>
        <Route path="/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/thank-you">
          <ThankYouPage />
        </Route>
        <Route path="/" >
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

