import React, {useEffect,useRef} from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
import { ChartComponent } from 'components/features/LiveChar';
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyChoose from "components/features/TwoColWithThreeCardsLayout.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import OnlineBenefits from "components/features/ThreeColWithCenterImage.js";
import ToolsInfo from "components/tools/Tools.js";
import PracticeHearing from "components/tools/PracticeHearing.js";
import PracticeReading from "components/tools/PracticeReading.js";
import PracticeAccuracy from "components/tools/PracticeAccuracy.js";
import TranslateText from "components/tools/TranslateText.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
//import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import DesignIllustration from "images/banner.png";
import BroadCastImage from "images/aurum_Icons/broadcast.svg";
import ConferenceImage from "images/aurum_Icons/conference.svg";
import OnlineImage from "images/aurum_Icons/online.svg";
import EbooksImage from "images/aurum_Icons/ebook.svg";
import EventsImage from "images/aurum_Icons/event.svg";
import WebinarsImage from "images/aurum_Icons/web.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import RightBanner from "images/banner1.png";
//import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import VideoContentImage from "images/youngminds_language.png";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import MythImage from "images/myths.png";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
//import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import StrongBrandImage from "images/learn_german.png";
import { components } from "ComponentRenderer.js";

// Live Chat plugin Tawkto
import tawkTo from "tawkto-react";

// seo plugin Helmet
import { Helmet } from 'react-helmet';
import Pronounciation from "images/icons/pronounciation.png";
import LiveClasses from "images/icons/live_classes.png";
import AffordablePricing from "images/icons/pricing.png";
import Hearing from "images/icons/hearing.png";
import Reading from "images/icons/reading.png";

import AcademicPartner from "images/icons/academic_partner.png";
import DynamicResources from "images/icons/dynamic_resources.png";
import PersonalDiscussion from "images/icons/discussion.jpg";
import AnaylsisReport from "images/icons/Analytics.jpg";
import FlexibleLearning from "images/icons/flexible_learning.png";
// const fs = require('fs');
//  <input type="file" id="filepicker" name="fileList" webkitdirectory='true' multiple  onChange={(e) => handleChange(e.target)} placeholder="Please choose directory having name" />
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const ref = useRef(null);
  const Subheading = tw.p`tracking-widest text-textBlack text-center font-medium`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`bg-baseGray overflow-hidden`;
  const Title = tw.h1`font-bold text-2xl md:text-3xl lg:text-3xl xl:text-5xl text-white leading-tight pt-4 md:pt-40 lg:pt-0`;
  const Gtext=tw.span`text-[#35f7f5] font-bold xl:text-4xl`;
  const Ltext=tw.span`text-brand-700 font-bold`;
  const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
  
  const getSelectedId=(id)=>{
    //console.log(id);
    let secId= id.toString();
    //console.log(secId);
    const element = document.getElementById(secId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }

  }
  
  // const tawkToPropertyId = '64538ccb31ebfa0fe7fbedf0';
  // const tawkToKey = '1gvj5rvt5';
  // useEffect(() => {
  //      tawkTo(tawkToPropertyId, tawkToKey)
  //  }, [])
  return (
    <>
      <Helmet
      
      title='HAPPYNESS PRO - Rule Based Trading'
      
      meta={[
        {
          name: 'description',
          content: 'Deploy small funds in Rule Based Trading (Not Investment), enjoy the Happyness of Second Income Without Hassles.',
        },
        {
          property: `og:title`,
          content: 'HAPPYNESS PRO - Rule Based Trading',
        },
        {
          property: `og:image`,
          content: "https://aurumfiles.b-cdn.net/hp_logo.png",
        },
        {
          property: `og:description`,
          content: 'Empower Your Savings for a Second Income by Deploying Small Funds in Rule Based Trading (Not Investment)',
        },
        {
          property: `og:image:alt`,
          content: "HappyNess Pro About Rule Based  Strategies",
        },
        {
          property: `og:image:width`,
          content: "500",
        },
        {
          property: `og:image:height`,
          content: "500",
        },
        {
          property: `og:url`,
          content:"http://happyness.pro/",
        },
        {
          property: `og:type`,
          content: "website",
        },
        {
          property: `og:site_name`,
          content: "Happyness.pro",
        },
      
      ]}
    />
   

    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} 
        mainTitle={<Title>Enjoy Parallel Income with Small Funds </Title>} 
        subTitle="Deploy small funds in Rule Based Trading (Not Investment), <br/>enjoy the <b>Happyness</b> of Second Income Without Hassles."
        buttonText="Lets Talk"
        imageSrc={RightBanner}
        showClient= {true}
      />
      
        <Features
          subheading={<Subheading>Deploying a Trading System is Very Simple <b>BUT</b> Requires Effort to Earn from Algo</Subheading>}

          heading={
            <>
              3 Steps to Successful Second Income
            </>
          }
        />
        {/* <VerticalSeprator className="v-seperator" /> */}
        {/* <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“Our company recently partnered with Young Minds for German language online classes for our employees, and we have been thoroughly impressed with the results. The classes are tailored to meet the needs of our team, and the instructors are knowledgeable, professional, and accommodating.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Deepak Tekchandani"
          customerTitle="Founder, CEO"
          buttonUrl= ""
        />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.prospectsofgermanlanguage.url}
          heading={<Heading>Develop Strong <Gtext>German</Gtext> <Ltext>Language Foundation</Ltext> with Unique Pedagogy</Heading>}
          description="Boost Your Global Career Goals by Clearing Goethe Certificate Exams - Fit in Deutsch for School Learners. Clearing <b>Gothe Certificate Exams establishes Your Strong German Language Foundation</b> which is a boon when you wish to Study Abroad or Aspire for Global Careers.<br/><br />  Get the best, Most Researched and Unique Learning Experience as we understand German Learning requires Exceptional Teaching Methodology and Language Learning Framework.<p>The platform offers a comprehensive curriculum covering all aspects of German language learning, including grammar, vocabulary, reading, writing, listening, and speaking.</>"
          subheading=""
          textOnLeft={false}
          imageSrc={StrongBrandImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.germanbylanguagepantheon.url}
          heading={<Heading>Delivering Best <Gtext>German</Gtext> Language Courses Powered by <Ltext>Language Pantheon</Ltext>  Institute</Heading>}
          description="Language Pantheon is the Best Institute for German Language Learning, having a faculty team of over 100 teachers, streaming live german courses in 40+ countries and training over 8000 learners across the globe. Language Pantheon has <b>Unparallel Track Record</b> of over 15 Years for German Language Coaching and nearly <b>100% Success Rate</b>.<p>Young Minds delivers India's Top Most German language courses on your mobiles / laptops, powered by AI Enabled App with Live and Recorded Videos</p><p>Our Courses are Focused on <b>Fit in Deustch & Other Goethe Certifications and CBSE Curriculum for Classes 7 to 12.</b></p>"
          subheading=""
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        /> */}
        
        <OnlineBenefits />
        {/* <ToolsInfo selectedId={getSelectedId}/> */}
        
        <MainFeature
          
          heading="Let's Clear Up Some Common Myths:"
          description="<p><b>Myth 1: Set it and Forget It</b>
          <br /><b>Reality: </b>While algos automate trade execution, they aren't magic money machines. Monitoring is crucial. We need to check for errors, adjust strategies based on market changes, and manage risk continuously.</p>
          <p><b>Myth 2: Guaranteed Profits / High Profits</b><br /><b>Reality: </b>Algo trading doesn't guarantee riches. Backtesting (testing strategies with historical data) is vital, but past performance doesn't predict the future. Markets are dynamic, and thus our team constinuously optimise strategies, retest and deploy to adapt to market situations.</p>
          <p><b>Myth 3: Algo Trading is Easy Money</b><br /><b>Reality: </b>While automation streamlines trading, it requires skill, strategy, and continuous monitoring. It's not a get-rich-quick scheme. But it Surely can turn a Small Savings into a Wealth.
          </p>
          "
          subheading=""
          textOnLeft={false}
          imageSrc={MythImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <WhyChooseWrap>
          <WhyChoose 
            heading="HappyNess Pro"
            subheading="Small Savings, Big Future with Strategic Trading"
            noCardSpace={true}
            cards={[
              {
                imageSrc: DynamicResources,
                subText: "Fully backtested, researched and risk managed trading strategies for small to large funds deployment.",
                title: "Exclusive Strategies",
                
              },
              {
                imageSrc: PersonalDiscussion,
                subText: "Our Experts will Help You to Choose the Best Strategy as per Your Funds, Knowledge and Trading Psychology.",
                title: "Personalised Discussion",
                
              },
              {
                imageSrc: AnaylsisReport,
                subText: "A detailed analysis of stock of your choice on Daily and Weekly Timeframes for Your Better Insights.",
                title: "Technical Analysis Report",
                
              },
              {
                imageSrc: Pronounciation,
                subText: "Coding of a strategy in Pine Editor (TradingView Only) as per your requirements..",
                title: "Customised Script Coding",
                
              },
              {
                imageSrc: Hearing,
                subText: "Learn and Use Trading Setups for Personal Trading / Portfolio Management.",
                title: "Trading Setups",
                
              },
              {
                imageSrc: Reading,
                subText: "Gain Confidence with simulated funds before risking real money.",
                title: "Paper Trading",
                
              },
              {
                imageSrc: FlexibleLearning,
                subText: "Set rules for automatic buy and sell orders based on your selected strategy.",
                title: "Order Automation",
                
              },
              
              {
                imageSrc: LiveClasses,
                subText: "Live Interactive Sessions to Learn from other traders and access educational resources.",
                title: "Community & Education",
                
              },
              {
                imageSrc: AffordablePricing,
                subText: "Plans designed for the needs and budget of small investors.",
                title: "Affordable Pricing",
                
              },
            
              
              
            ]}
            isAnimate = {true}
          />
        </WhyChooseWrap>
        {/* <ChartComponent/> */}
        <PreFooter fromPage="Home Page"/>
      
      <Footer />
      </AnimationRevealPage>
   </>
  );
}

