import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative bg-white lg:px-0 xs:px-2`;
const Highlight = tw.span`text-left text-base xl:text-sm text-[#dc3188] font-bold px-2`;
const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`text-blue-700 font-bold !text-lg inline-block mx-auto md:mx-0 rounded-full mt-4`
]);
const Content = tw.p`text-blue-700 font-bold text-sm inline-block mx-auto md:mx-0 rounded-full`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch md:flex-row flex-wrap md:justify-start py-10 md:py-4`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-left`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-3/4`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }


  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;
const ParaBlock = styled.div`
  ${tw`w-full mb-6`}
`;
const Title = styled.h4`
  ${tw`text-xl font-bold text-gray-900 text-left`}
`;


const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Paragraph = styled.div`
    ${tw`w-full text-lg leading-loose text-gray-600 font-medium`}
    .blogContainer{
      ${tw`px-2 md:pr-10 lg:pr-10`}
    }
    
    .blogContainer h1{
      ${tw`my-10 font-bold text-3xl !text-black leading-tight`}
    }
    .blogContainer h2{
      ${tw`mt-2 mb-4 font-bold !text-xl !text-black leading-tight`}
    }
    .blogContainer ul{

      list-style:disc;
      margin-left:19px;
      width:90%;
      margin-top:5px;
      margin-bottom:20px;
    }
    .blogContainer li{
      color:#000 ;
      font-weight:500;
    }
    .blogContainer p{
       ${tw`mb-4 font-medium text-base !text-[#191C1F] leading-7 text-justify`}
    }
    .blogContainer strong{
       ${tw`!font-semibold !text-black`}
    }
    .blogContainer span{
       ${tw`font-bold !text-blue-900 text-lg`}
    }
    .blogContainer img{
       ${tw`w-auto my-6`}
    }
   

`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto xs:py-10 md:py-2`;
const LeftColumn = tw.div`relative lg:w-[75%] pt-4 text-left max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative p-5  mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;
/*const RightColumn = styled.div`
  ${tw`bg-[#f5f5f5] md:w-1/2 lg:w-1/4 py-4 px-8`}
`;
*/
const Sectionhead = styled.h2`
  ${tw`!text-xl font-semibold text-gray-900 text-left mb-6`}
`;
const LinkBlock = styled.div`
  ${tw`flex flex-col space-y-4 `}
`;
const Link = styled.a`
  ${tw`text-[#121a23] border-b-4 leading-loose`}
`;
export default ({ data, relatedData, cards = null, heading = "Our Offices", subheading = "Locations", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  //console.log(data);
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: "Branding",
      description: "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!"
    },
    { 
      title: "Scalable",
      description: "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!"
    },
    { 
      title: "Reliable",
      description: "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!"
    },
    { 
      title: "Engaging",
      description: "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!"
    },
    { 
      title: "Customizable",
      description: "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!"
    },
    { 
      title: "Fast",
      description: "Need to engage your audience? Download incredible Social Media Illustrations to showcase powerful images for free. From online interactions and social apps to user reactions, have a look at our designs and bring these graphics to life with our animation features!"
    },
  ];

  if (!cards) cards = defaultCards;
/*{data &&(
              <div  dangerouslySetInnerHTML={{ __html: data }}></div>
            )}  */
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <ParaBlock>
            <Paragraph>
          
              {data &&(
                <div  className="blogContainer" dangerouslySetInnerHTML={{ __html: data }}></div>
              )}
             
            </Paragraph>
          </ParaBlock>
        </LeftColumn>
        <RightColumn>
          <div style={{backgroundColor:"#f5f5f5", padding:"10px"}}>
            <Sectionhead>
              Related Topics
            </Sectionhead>
          
            <LinkBlock>
              {(relatedData) &&(
                <>
                  {relatedData.map((val)=>(
                      <Link href={val.link.toLowerCase()} target="_blank">
                        {val.title}
                      </Link>
                    ))}
                </>
              )}
            </LinkBlock>
          </div>
          <div>
            <LinkButton  className="exploreBtn" as="a" href="https://www.happyness.pro/iifl-trading-account" >
              <Content>Connect to  <Highlight> open a new Trading Account with IIFL</Highlight></Content>
            </LinkButton>
          </div>
        </RightColumn>
      </TwoColumn>
      
      <DecoratorBlob />
    </Container>
  );
};
