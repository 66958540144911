// import React from "react";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/light.js";
// import Footer from "components/footers/SimpleFiveColumn.js";
// import MainBanner from "components/hero/YoungHeroContent.js";
// import FastestGrowing from "components/features/FastestGrowing.js";
// import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
// import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
// import Faqs from "components/faqs/SimpleWithSideImage.js";
// import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import { Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { ReactComponent as Checkicon } from "../images/check.svg";
// import ContactDetails from "components/cards/ThreeColContactDetails.js";
// import PreFooter from "components/footers/PreFooterBrand.js";
// import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
// import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
// import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"

// const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
// const HighlightedText = tw.span`text-blue-900 `;


// const BoldTag = styled.span`
//    ${tw`text-textBlack font-bold`}
// `;
// export default ({
//  title = "Simplified On-Boarding",
//  highlightedtext = "Get Started.",
//  heading = "Easy to",
//  CheckIcon = Checkicon,

// }) => { 
//   return (
//     <AnimationRevealPage>
//       <MainBanner roundedHeaderButton={true} 
//         mainTitle="Say Hello to a Better Way to Deliver Digital Content" 
//         subTitle="Agile & Secure Platform build to Deliver Content at Enterprise Scale. Streamline and Modernise Your Digital Content Experience.Agile & Secure Platform build to Deliver Content at Enterprise Scale. Streamline and Modernise Your Digital Content Experience."
//         buttonText="Let's Talk"
//         buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
//         imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
//         showClient= {true}
//         text="1"
//         knowmoreUrl=""
//         />
//       <TrackRecord 
//         textOnLeft={true}
//         heading={
//           <>
//             Why <HighlightedText>Aurum</HighlightedText> is the Best Choice
//           </>
//         } description = 
//           {
//             <>
//               Its the New Front Door for the Brands to Deliver Content Across Globe on Almost All Devices. Aurum makes it easy for the Content Owners and Developers to Build Faster and Manage Delivery at Scale.<p>&nbsp;</p><p>It is the easiest and fastest way to Streamline, Modernise and Monetise Content. <BoldTag>Let’s build better digital experiences together.</BoldTag></p>
//             </>

//           } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-50/why_aurum_2.png"
//         subheading = "The Modern Content Delivery & Monetisation Platform"
//       />
//       <Faqs
//         textOnLeft={false}
//         buttonUrl="https://wa.me/918920403902/?text=Hi, How can i GROW my Digital Content Business with Aurum"
//         buttonText="Lets Connect"
//       />
//       <Testimonials description = {
//         <>
//           We work with you from start to finish. We view ourselves as an extension of your team, and as your partner, we pride ourselves in being fully engaged with your company to help you find opportunities for growth. <BoldTag>The Reason why Content Owners Love Aurum</BoldTag> 
//         </>
//         } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
//         textOnLeft={true}
//       />
//       <FastestGrowing />
//       <PreFooter fromPage = "Content Delivery Platform" formheading = "Kickstart your journey with rule based trading here." formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Structured Content Distribution Platform"/>
//       <Footer />
//     </AnimationRevealPage>
//   );
// };





import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/UpcomingSeminar.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImageAndHeader.js";
import FAQ from "components/faqs/SingleCol.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import MainFeature from "components/features/TwoColWithButton.js";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import gols from "images/goals.png";
import upcoming2 from "images/upcoming.png";
import ideal from "images/ideal.png";
import coursedetail from "images/course detail.png";
import { Helmet } from 'react-helmet';
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 `;
const Title = tw.h2`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const batchHead = tw.span`text-black leading-tight`;
const Gtext=tw.span`text-blue-900 font-bold`;
const Ltext=tw.span`text-brand-700 font-bold`;
const Subheading = tw.p`tracking-widest text-textBlack`;
const BoldTag = styled.span`
   ${tw`text-textBlack font-bold`}
`;
export default ({
 title = "Who Should Attend ? ",
 highlightedtext = " You - ",
 heading = "Ideal If",
 CheckIcon = Checkicon,

}) => { 
  return (
    <>
    <Helmet
      
      title='HAPPYNESS PRO - Upcoming Seminar'
      
      meta={[
        {
          name: 'description',
          content: '"Decoding the Myths of Trading: Unraveling Truths of Quick Profits',
        },
        {
          property: `og:title`,
          content: 'HAPPYNESS PRO - Upcoming Seminar',
        },
        {
          property: `og:description`,
          content: 'Join us for an enlightening session that promises to unravel the mysteries of trading enhancing your trading wisdom.',
        },
        {
          property: `og:image`,
          content: "https://aurumfiles.b-cdn.net/hp_logo.png",
        }, 
        {
          property: `og:image:alt`,
          content: 'HappyNess Pro - Rule Based Trading',
        },
        {
          property: `og:image:width`,
          content: '500',
        },
        {
          property: `og:image:height`,
          content: '500',
        },
        {
          property: `og:url`,
          content: 'https://happyness.pro/upcoming-seminar',
        },
        {
          property: `og:type`,
          content: 'website',
        },
        {
          property: `og:site_name`,
          content: "Happyness.pro",
        },
        ]}
    />
    <AnimationRevealPage>
      
      <Faqs
         imageSrc={upcoming2}

        roundedHeaderButton={true}
        heading={<Title>Upcoming Seminar </Title>}
        description={<batchHead>Attend our Seminar on <BoldTag>"Decoding the Myths of Trading: Unraveling Truths of Quick Profits"</BoldTag>  , and delve into the intricacies of trading by decoding prevalent myths and revealing the truths behind quick profit strategies in association with IIFL. <br />We aim to provide you with valuable insights and knowledge that will empower your trading journey. Join us for an enlightening session that promises to unravel the mysteries of trading enhancing your trading wisdom.</batchHead>}
        textOnLeft={false}
        buttonUrl="https://wa.me/918920403902/?text=Hi, I want to Know more about Upcoming Seminar"
        buttonText="Lets Connect"
      //   faqs={[{
      // question: <batchHead><Ltext>Scheduled On </Ltext> - 4th May 2024, Saturday, 12.00 PM</batchHead>,
      // answer:
      //   "<p><b>The session will be of 75 Mins with Q&A Session from the Attendees.</b> </p>"
      //   },
      //   {
      // question: <batchHead><Ltext>Steps to Join the Seminar</Ltext> </batchHead>,
      // answer:
      //   "<p><b>Fill in the Google Forms at the link - https://bit.ly/49P2vfi and our team will get in touch with you shortly</b></p>"
      //   },
      //   ]}

      />

      <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={ideal}
          steps={[
            {
              mainIcon: "",
              heading: "Think Trading can be done Part Time. ",
              description:
                "Its the greatest myth. Trading required dedication and discipline, and is a full time profession. ",
            },

            {
              mainIcon: "",
              heading: "Think its Easy Money in Stock Market Trading",
              description:
                "Dont be swayed away by lucrative Stock Market Earnings Ads. Its way far from Reality.",
            },

            {
              mainIcon: "",
              heading: "Think Markets can be Mastered in Short Period of Time",
              description:
                "Trading is more of a Mental and Psychological Training. It Required Times and Efforts",
              
            },
          ]}
        />

      <FastestGrowing />
      {/* <MainFeature
        primaryButtonText="Let's Talk"
        primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, I would like to know more about Fit in Deutsch"
        heading="Take the Stress Out Of Language Learning"
        description="German Language Learning for Goethe Certifications seems complicated, but it does not have to be. Young Minds and Language Pantheon takes care of the entire language learning process, enabling you to learn German quickly and easily.<p><b>Interactive Live Video Sessions</b><br />Allows students and instructors to engage in real-time, face-to-face communication, enabling a more immersive and personalized learning experience.</p><p><b>Flexibility in Learning</b><br />Accessible from Mobile / Laptops, plus recordings can be used in case u miss a session or to revise. Learn anytime, anywhere.</p><p><b>Learn from the Best Faculty</b><br />LP is the most powerful brand for German Courses in India with a faculty pool of over 100 Faculty. You are in a good company.<p><b>Multimedia Content Library</b></b><br />A diverse collection of learning resources, including videos, audio clips, and reading materials, that cater to various learning styles and reinforce language skills through engaging content and AI Enabled Tech Platform.</p>"
        subheading="Key Highlights / Features of the Programs"
        textOnLeft={true}
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/live_video_stress.gif"
        imageBorder={false}
        imageDecoratorBlob={false}
      /> */}
      {/* <TrackRecord 
        textOnLeft={false}
        heading={
          <>
             General Info About <HighlightedText>Fit in Deutsch 1</HighlightedText> 
          </>
        } description = 
          {
            <>
             <p> The Fit in Deutsch 1 exam is designed for children and adolescents <BoldTag>aged between 10 and 16 Years</BoldTag> who want to test their German language proficiency. This certification confirms that candidates have gained fundamental language abilities and corresponds to level A1 on the Common European Framework of Reference for Languages (CEFR) six-level competence scale</p>
              <br />
              <BoldTag>Passing the Exam Demonstrates ...</BoldTag>
              <p>1. you can ask and reply to simple questions as well as make and respond to requests,<br />2. you can write about yourself and others using simple expressions and sentences,<br />3. you can understand conversations and texts about familiar topics.</p>
              <br />
              <BoldTag>Exam Pattern of Fit in Deutsch 1</BoldTag>
              <p>The Goethe-Zertifikat A1: Fit in Deutsch 1 exam comprises of 4 sections - <HighlightedText>reading, listening, writing and speaking</HighlightedText> sections. The speaking section of the exam is completed in a group.
The exam is <BoldTag>administered and evaluated in the same way all over the world</BoldTag>.</p>
            </>

          } 
          imageSrc={coursedetail}
        subheading = "First Step for Young Learners Towards German Proficiency"
      /> */}
      {/* <FeatureWithSteps
        subheading={<SubheadingSteps>{title}</SubheadingSteps>}
        heading={
          <>
            {heading} <HighlightedText>{highlightedtext}</HighlightedText>
          </>
        }
        textOnLeft={true}
        imageSrc={gols}
        steps={
            [
              { 
                mainIcon:"",
                heading: "Are a Study Abroad Aspirant",
                description: "Germany is the Top Destination for Graduation and Post Graduation."
                
              },

              {
                mainIcon:"",
                heading:"Want to Learn Basics of German",
                description: "Take the First Step to German Proficiency, Open the World of Opportunities."
              },

              {  
                mainIcon:"",
                heading: "Wish to Get Global Certification",
                description: "Get an Official and Internationally recognized Goethe Certificate." 
              }
            ]
          }
      /> */}
      
      {/* <Faqs
        
        imageSrc = "https://aurumfiles.b-cdn.net/faq2.jpg"
        textOnLeft={false}
        heading="FAQ about Fit in Deutsch - 1"
        showHeader={false}
        buttonUrl="https://wa.me/918920403902/?text=Hi, I need more info on Fit in Deustch Exam ..."
        buttonText="Lets Connect"
        description="Here are some frequently asked questions about Goethe-Zertifikat A1: Fit in Deutsch 1 Exam, feel free to reach out in case of we missed out something."
        faqs={[{
      question: "What are the Eligibility / Requirements of this Exam ??",
      answer:
        "<p>The Goethe-Zertifikat A1: Fit in Deutsch 1 is a German exam for children and young people between 10 and 16 years of age.</p><p>The Goethe-Institut exams are available to all interested parties and can be taken regardless of age and nationality.</p><p>To sit the Goethe-Zertifikat A1: Fit in Deutsch 1 exam, candidates must have German language skills corresponding to the first level of competence (A1) of the Common European Framework of Reference for Languages (CFR).</p>"
        },
        {
      question: "How is Speaking Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to introduce yourself, ask questions on everyday matters and answer simple questions. You will also be required to make requests and respond to your conversation partner in situations with which you are familiar.</p><p><b>Duration of the Speaking Ability Test is 15 Mins</b></p>"
        },
        {
      question: "How is Listening Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to listen to short everyday conversations, personal telephone messages, information on the radio and similar recordings and complete various exercises on what you have heard.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>"
        },
        {
     question: "How is Reading Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to read texts such as brief notes, classified advertisements, descriptions of people, simple letters to the editor or short newspaper articles and complete exercises on these texts.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>"
        },
        {
     question: "How is Writing Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to reply to an email, letter, advertisement or similar piece of correspondence.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>"
        }]}
      /> */}
      {/* <Testimonials 
        subheading = "Learn German from the Best Institute in India"
        heading = "Our Students Love Us."

        description = {
        <>
          <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is that we work with you from start to finish. We pride ourselves in being fully engaged with your german learning journey, to help you open global opportunities for education and career growth.  
        </>
        } 
        imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
        textOnLeft={true}
        testimonials={[{
          stars: 5,
          profileImageSrc:"https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
          heading: "Didn’t Expected Language Learning can be So Interesting & Engaging",
          quote:"My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
          customerName: "Showick Thorpe",
          customerTitle: "Founder, V&S Thorpes Education Inc."
          
        },
        {
          stars: 5,
          profileImageSrc:"https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
          heading: "Love the Teaching Experience and Technology Tools",
          quote:"I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
          customerName: "Amit Chawla",
          customerTitle: "FCA, Content Creator at EY"
        },
        {
          stars: 5,
          profileImageSrc:"https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
          heading: "Individualized Attention & Effective Learning",
          quote:"We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
          customerName: "Achal Gupta",
          customerTitle: "CEO, Kiran Prakashan" 
        }


        ]}
      /> */}
      {/* <FastestGrowing />
      <FAQ
       
          subheading={<Subheading></Subheading>}
          description={'Feel Free to Get in Touch if We Missed Out Something...'}
          heading={
            <>
              FAQ about Coaching Program
            </>
          }
          faqs={[
            {
              question: "Who should do this course ?",
              answer:
                "<p>All those students who set their target to go Germany for Graduation / Post Graduation should definitely do this course.</p><p> Otherwise, this course is a starting point to learn a foreign langauge German with a Certification. German is widely used in Private & Govt Sector and thus promoted by CBSE in schools. It will surely help in your career.</p>"
            },
            {
              question: "How is your coaching program different from any other system? Why should I join your institute only?",
              answer:"<p>Our training system is based on learning concepts and applying them, Memorizing is prohibited in our teaching style.</p><p>Language learning <b>needs an environment to interact, read & speak without hesitation, learn and improvise</b> under the guidance of a faculty team with proven track record.</p><p style='padding-top:10px'><b>More than 80% people who go for the German Language exam every time are just from our academic partner institute - Language Pantheon.</b> We work with you till you succeed, because we are confident of our system and we know that a serious effort from your side will get you the Certification.</p>"
            },
            {
              question: "Do you guarantee result?",
              answer:
                "CERTAINLY.We do guarantee the result provided you follow our instructions blindly and do whatever we ask you to do. BUT LET US DEFINE THE WORD “GUARANTEE”: It doesn't mean that you will appear in the exam and you will clear it. It means that you will get the result at any cost. If for bad luck or any reason, you are not able to clear a module or two, we will provide classes again without any extra cost. We never ever charge fee in the name of wrong things. You will be with us till the date you have not received the certificate in your hands and German on your tongue."
            },
            {
              question: "What if my English is not good? Can I manage?",
              answer:
                "Yes. No need to worry about it. Even if your English is not good, you can easily manage. In other words, you are not reading one language, but two languages simultaneously in the same fees."
            },
            {
              question: "I want to take admission in Germany for Graduation. Will You help me?",
              answer:
                "Yes. We help our students till the point any consultant helps after taking the money. WE DO NOT CHARGE A SINGLE PENNY FOR IT i.e. WE DO THIS ABSOLUTELY FREE FOR OUR STUDENTS and we don't offer this support to the people who are not our students. We need to clarify here that please don't put us in the category of consultants as this is a small help/gift for our students only."
            }
          ]}
        />
      
       */}
      <PreFooter fromPage = "About Fit in Deutsch 1" formheading = "Learning a Foreign Language in School Life Amplies Your Career Prospects and Personality" formsubheading = "Get More Details About the Program." heightlightText="Lets Start Learning"/>
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
