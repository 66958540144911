import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
// import { ReactComponent as Hearing } from "images/hearing.svg";
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/kiranprakashan.png"
import DBMI from "../../images/clientLogos/dbmi.png"
import Igp from "../../images/clientLogos/igp.png"
import Hearing from "../../images/hearing.svg"
import Reading from "../../images/reading.svg"
import Speak from "../../images/speaker.png"
import Mic from "../../images/mic.png"
import { SectionHeading } from "components/misc/Headings.js";
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"
import { useSpeechSynthesis,useSpeechRecognition  } from 'react-speech-kit';
const Section = styled.div`
	${tw`relative xs:px-10 py-20 md:py-10 bg-whiteSmoke`}
`;
const Container = styled.div`
	${tw`relative text-left max-w-[1280px] mx-auto`}
`;
const IconContainer = tw.i``;
const Heading = tw(SectionHeading)`w-full  md:mb-20 max-w-[1170px] mx-auto`;
const MainBlock = styled.div`
	${tw`border border border-gray-400 mt-10 p-5`}
	.textStyle{
		${tw`flex flex-col md:flex-row justify-center gap-2 w-full`}
	}
	h3{
		${tw`relative first:w-[140px] mx-auto md:m-0 text-blackStep-200 text-4xl text-center border-b border-dotted border-black`}
		.tooltiptext{
			${tw`absolute hidden w-[150px] bg-black text-white text-center rounded-md py-2 left-0 top-[-62px] z-10`}
		}
		&:hover .tooltiptext{
			${tw`block`}
		}
	}
	
`;
const TextBlock = styled.div`
	${tw`border border border-gray-400 border-t-0 mb-4 bg-white`}
	input{
		${tw`w-full text-center mx-auto text-4xl font-normal md:font-semibold placeholder:text-gray-600  text-blackStep-200 p-2 md:p-5`}
	}
`;

export default ({
	heading = "Translate Text in German",

}) => {
  const [value, setValue] = useState('');
  const [show,setShow] = useState(false);
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setValue(result);
    },
  });
  const handleMic= ()=>{
  	listen();
	setShow(!show);
  }

  return (
  	<Section>
	    <Container>
	    	<Heading>{heading}</Heading>
	    	<MainBlock>
				<div className="textStyle">
					<h3>Thanks,<span className="tooltiptext">danke</span></h3> 
					<h3>see you soon<span className="tooltiptext">bis bald</span></h3>
				</div>
			</MainBlock>
			<TextBlock>
				<input type="text" placeholder="Type Here"/>
			</TextBlock>
	    </Container>
	</Section>
  );
};
