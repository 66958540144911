import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { components } from "ComponentRenderer.js"; 
import Zee5image from "../../images/mediaLogos/Zee5.png";
import Edtimes from "../../images/mediaLogos/edtimes.png";
import Midday from "../../images/mediaLogos/midday.png";
import Dailyhunt from "../../images/mediaLogos/dailyhunt.png";
import { Helmet } from 'react-helmet';
// import Igiaviation from "../../images/mediaLogos/igiaviationdelhi.png"

const Container = styled.ul`
	${tw`relative py-10`}
	img{
		${tw`xs:h-full pr-10 `}
	}

`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const ColumnHeading = tw.h2`xs:text-center w-full text-black pt-5`;
const MediaWrapper = tw.div`flex lg:flex-wrap justify-center lg:flex-row my-8 xs:mb-5 xs:flex-col xs:items-center xs:pl-10 `;
const Link = tw.a``;
export default ({
	mediaLogos = [
	    {
	  		logoSrc: Zee5image,
	  		mediaName: "zee5image",
	  		mediaLink: "https://www.zee5.com/articles/aurum-labs-enabling-national-brands-achieve-inevitable-success-in-online-education-business"
	  	},

	  	{
	  		logoSrc: Midday,
	  		mediaName: "midday",
	  		mediaLink: "https://www.mid-day.com/brand-media/article/aurum-labs-enabling-national-brands-achieve-inevitable-success-in-online-education-business-23223564"
	  	},

	  	{
	  		logoSrc: Dailyhunt,
	  		mediaName: "dailyhunt",
	  		mediaLink: "https://m.dailyhunt.in/news/india/english/ed+times-epaper-edtimes/aurum+labs+enabling+national+brands+achieve+inevitable+success+in+online+education+business-newsid-n378427140?s=a&uu=0xa75317646e3f2bc8&ss=wsp"
	  	},
	  	
        {
	  		logoSrc: Edtimes,
	  		mediaName: "edtimes",
	  		mediaLink: "https://edtimes.in/aurum-labs-enabling-national-brands-achieve-inevitable-success-in-online-education-business/"
	  	},
	  	
	   ]

}) => {
  

  return (
  	<>
  	<Helmet
      
      title='Aurum Labs - Content Delivery and Monetisation'
      
      meta={[
        {
          name: 'description',
          content: 'Aurum Labs Enabling National Brands Achieve Inevitable Success in Online Education Business',
        },
        {
          property: `og:title`,
          content: 'Aurum Labs - Content Delivery and Monetisation',
        },
        {
          property: `og:description`,
          content: 'Aurum Labs Enabling National Brands Achieve Inevitable Success in Online Education Business',
        }]}
    />
    <Container className="wr-medias">
	    <Heading>Aurum In Media</Heading>
	  	<MediaWrapper>
      	{mediaLogos.map((item, index) => (
		    <Link href={item.mediaLink} target="_blank">
				<img src={item.logoSrc} alt={item.mediaName} />
			</Link>	
		))}	
		</MediaWrapper>
    </Container>
   	</>
  );
};
