import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import TradingImage from "images/trading.png"

const Container = styled.div`
  ${tw`relative text-gray-100 -mx-8 px-8 py-10 xs:px-12 border-b-8 border-blue-900`} 
  background: rgba(0, 0, 0,1);
`;
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-5`
const TwoColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`sm:w-full md:w-1/2 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;

const ColumnHeading = tw.h2`text-white`;
const Subheading = tw(SubheadingBase)`mb-4 text-white text-base font-normal`;
export default ({
  formheading = "Kickstart Your Journey With Rule Based Trading Here.", 
  formsubheading = "Book Our Free Discussion Session Today -",
  // heightlightText = "Why and When One Should Plan to Start Learning German",
  fromPage = "",
  wappAllow = true,
  formActionUrl = "",
}) => {
  return (
    <Container>
      <Content>
        <TwoColumns>
          <Column>
            <ColumnHeading>{formheading}</ColumnHeading>
            {/* <ColumnHeading>&nbsp;</ColumnHeading> */}
            <Subheading> { formsubheading } </Subheading>
            <ContactUsForm fromPage={fromPage} wapp={wappAllow} formActionUrl = {formActionUrl} />
          </Column>
          <Column>
            <img src={TradingImage} />
          </Column>
        </TwoColumns>
      </Content>
    </Container>
  );
};
