import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import FeaturesLive from "components/features/LiveThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";

import TestFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";

import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import UiUxImage from "images/aurum_Icons/uxcx-engagement.svg";
import agilityImage from "images/aurum_Icons/agilityImage.svg";
import unifiedLibrary from "images/aurum_Icons/unifiedLibrary.svg";
import { Helmet } from 'react-helmet';
import { components } from "ComponentRenderer.js";
export default ({landingPages = components.landingPages,
  innerPages = components.innerPages}) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const TITLE = 'Aurum Apps for Mobiles & Desktops';
  const tagNameD = 'Customised Apps, Standard Apps and Aurum Platform';

  return (
    <>
      <Helmet
      
      title={TITLE}
      
      meta={[
        {
          name: 'description',
          content: {tagNameD},
        },
        {
          property: `og:title`,
          content: {TITLE},
        },
        {
          property: `og:description`,
          content: {tagNameD},
        }]}
    />
      <Hero roundedHeaderButton={true} 
        mainTitle="Flexible & Easy Ways to Deliver & Monetise Content" 
        subTitle="Aurum makes it fast and easy for Content Owners to streamline, customise, modernise and monetise their content."
        buttonText="Lets Go"
        buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to GROW my Business."
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/aurum_apps_home_2.gif"
        showClient= {true}
      />
      
      <FeaturesLive
          subheading={<Subheading>Aurum Apps Enable Easy Content Monetisation for New, Medium and Large Players</Subheading>}

          heading={
            <>
              Accelerate Your Content Business <br />Start Quickly, Build As You Grow
            </>
          }
          cards={
            [
              { 
                imageSrc: agilityImage, 
                title: "Customised Aurum Apps",
                description: "Best Suited for Established Brands and Large Institutions." 
              },

              {
                imageSrc: UiUxImage,
                title: "Standard Aurum Apps",
                description: "Best Suited for New and Growing Content Creators."
              },

              { imageSrc: unifiedLibrary, 
                title: "Aurum Platform",
                description: "Best Suited for Individuals and New Content Creators" 
              }
            ]
          }
        />

      <AnimationRevealPage>
       
        <VerticalSeprator className="v-seperator" />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.customisedapps.url}
          heading="Customised Aurum Apps"
          description="Customised Apps are best suited when establishing a Brand Identity with excellent user experience is of utmost priority. Its done by improvising the entire user experience, with a detailed and exhaustive UX / UI. The App Flows, images, colors, logos, emails, etc. everything is designed to drive maximum value at all touch points.<p>These apps are most viable for Institutions and Large Organisations, where availablity of Human and Financial Resources is sufficient to develop and maintain the digital products.</p>"
          subheading="Own the App, embed the Digital Experience that Established Your Brand"
          textOnLeft={false}
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/aurum_apps_cutomised_apps.jpg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Lets Connect"
          primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, I would like to know more about Aurum Standard Apps for my Business."
          heading="Standard Aurum Apps"
          description="Standard Apps are best suited for growing business owners. These apps Unlock a great experience for your users that allows them to more meaningfully connect to Your Brand and Content.<p>These apps have fixed layouts for User Flow Designs and Content Delivery Framework, with possibilites of Minor Changes.</p><p>These apps have all the features required by Content Owners to Monetise in Best Possible Manner, with minimal requirement of in house support staff.</p>"
          subheading="Easy Onboarding, Cost Effective, White Labelled"
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/aurum_apps_standard_apps.jpg"
          imageBorder={false}
          imageDecoratorBlob={true}
        />
        <MainFeature
          primaryButtonText="Lets Connect"
          primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, I would like to know more about Aurum Platform for my Business."
          heading="Aurum Content Platform"
          description="The Content Owners can use Aurum Platform for Monetisation and Delivery <b>without developing app for any platform.</b><p>With a simple pay as you go pricing model, its best suited for New Content Business Owners, Non Profit Organisations or for Entities where content is occassionally broadcasted.</p><p>Aurum Platform is Agile, Secure and built to deliver at Scale, with prime focus to enable easy and flexible  monetisation for Content Creators.</p>"
          subheading="A Get, Set, Go Model"
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/aurum_apps_platform.png"
          imageBorder={false}
          imageDecoratorBlob={true}
          textOnLeft={false}
        />
        <TestFeature description="Deliver all most all types of contents on <b>Mobile Devices</b> - Android, iOS and Mobile Browsers, <b>Desktops & Laptops - Windows App, MAC App and Browser Access."/>
        <PreFooter fromPage = "Customised Apps" formheading = "Build and Grow Your Content Business with Aurum" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="Understand what is best for you and how you should start."/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
