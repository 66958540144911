import React, { useState, useEffect, useRef } from "react";
import { serverUtilities } from "../../services/serverutilities.service";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import tw from "twin.macro";
import styled from "styled-components";
import coursedetail from "images/risk.png";

import TrackRecord from "components/features/NiftyBankNiftyLevels.js";

import technicalcomparison from "images/technical_comparison.png";

const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 font-black`;
const Title = tw.h2`font-bold text-4xl mt-8 md:mt-0 lg:mt-0 md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const Gtext = tw.span`text-blue-900 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;

const NiftyIntradayTable = () => {
  const [date, setDate] = useState(new Date());
  const [res, setres] = useState("");
  const [chekurl, setchekurl] = useState("");
  const elementRef = useRef(null);
  var todayDate = new Date().toISOString().slice(0, 10);
  useEffect(() => {
    const currentUrl = window.location.href;

    console.log("Current URL:", currentUrl);

    // Split the URL by "/"
    const urlParts = currentUrl.split("/");

    // Get the last part of the URL
    const lastPart = urlParts[urlParts.length - 1];

    console.log("Last part of the URL:", lastPart);
    setchekurl(lastPart);

    const timerID = setInterval(() => tick(), 1000);
    serverUtilities.date(todayDate).then((resData) => {
      console.log(resData);
      setres(resData);
    });
    return () => clearInterval(timerID);
  }, []);

  const tick = () => {
    setDate(new Date());
  };

  useEffect(() => {
    const autoClick = () => {
      downloadClick();

      const url = window.location.href;
      console.log(url);
      // //alert(url);
      // let spliturl= url.split('/').pop();
      // if (spliturl == '') spliturl = 'blog';
      // //alert(spliturl);
      // console.log(spliturl);
    };

    const downloadClick = () => {
      const element = elementRef.current;

      html2canvas(element).then((canvas) => {
        const imageData = canvas.toDataURL("image/jpeg");
        const newData = imageData.replace(
          /^data:image\/jpeg/,
          "data:application/octet-stream"
        );
        const downloadLink = document.createElement("a");
        downloadLink.href = newData;
        downloadLink.download = "image.jpg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    };

    document
      .getElementById("download")
      .addEventListener("click", downloadClick);

    return () => {
      document
        .getElementById("download")
        .removeEventListener("click", downloadClick);
    };
  }, []);

  return (
    <>
      <Helmet
        title="HAPPYNESS PRO - Rule Based Trading"
        meta={[
          {
            name: "description",
            content:
              "Deploy small funds in Rule Based Trading (Not Investment), enjoy the Happyness of Second Income Without Hassles.",
          },
          {
            property: `og:title`,
            content: "HAPPYNESS PRO - Rule Based Trading",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/hp_logo.png",
          },
          {
            property: `og:description`,
            content:
              "Empower Your Savings for a Second Income by Deploying Small Funds in Rule Based Trading (Not Investment)",
          },
          {
            property: `og:image:alt`,
            content: "HappyNess Pro About Rule Based  Strategies",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content: "http://happyness.pro/",
          },
          {
            property: `og:type`,
            content: "website",
          },
          {
            property: `og:site_name`,
            content: "Happyness.pro",
          },
        ]}
      />
      <div>
        <div
          id="htmlContent"
          ref={elementRef}
          style={{ backgroundImage: "url('../images/dot.webp')" }}
        >
          <div className="nifty_table">
            <div className="nifty_content">
              {/* <div className="happynessText">
                <h1>HappyNess Pro</h1>
              </div> */}
              <div className="current_date">
                <h4>{date.toDateString()}</h4>
              </div>
            </div>
            <div className="table_content">
              {chekurl === "nifty-intraday-levels-using-technical-analysis" && (
                <div className="table_context1" style={{ overflow: "auto" }}>
                  <div className="nifty">
                    <h6>
                      Nifty-50 Levels for {res?.NIFTY ? "for " : ""}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {res?.for_dt}
                      </span>
                    </h6>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "14%" }}></th>
                        <th style={{ width: "14%" }}>R1</th>
                        <th style={{ width: "14%" }}>R2</th>
                        <th style={{ width: "14%" }}>Blue Zone</th>
                        <th style={{ width: "14%" }}>S1</th>
                        <th style={{ width: "14%" }}>S2</th>

                        <th style={{ width: "15%" }}>Break Down</th>
                      </tr>
                    </thead>

                    <tbody>
                      {res?.NIFTY?.map((item) => (
                        <tr key={item.id}>
                          <td style={{ fontWeight: "700" }}>
                            {item.text_main}
                            <br></br>
                            {item.updt_time !== "0" && (
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: 500,
                                }}
                              >
                                (Last Update: {item.updt_time})
                              </span>
                            )}
                          </td>

                          <td style={{ color: "#0ac405", fontWeight: "600" }}>
                            {item.r1}
                          </td>
                          <td style={{ color: "#0ac405", fontWeight: "600" }}>
                            {item.r2}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#0ac405",
                              fontWeight: "600",
                            }}
                          >
                            {item.bluezone}
                          </td>
                          <td style={{ color: "#e50303", fontWeight: "600" }}>
                            {item.s1}
                          </td>
                          <td style={{ color: "#e50303", fontWeight: "600" }}>
                            {item.s2}
                          </td>

                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#e50303",
                              fontWeight: "600",
                            }}
                          >
                            {item.breakdown}
                          </td>
                        </tr>
                      ))}
                      {/* Add more rows here if needed */}
                    </tbody>
                  </table>
                </div>
              )}
              {chekurl ===
                "banknifty-intraday-levels-using-technical-analysis" && (
                <div className="table_context2" style={{ overflow: "auto" }}>
                  <div className="bank_nifty">
                    <h6>
                      Bank Nifty Levels for {res?.BANKNIFTY ? "for " : ""}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {res?.for_dt}
                      </span>
                    </h6>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "14%" }}></th>
                        <th style={{ width: "14%" }}>R1</th>
                        <th style={{ width: "14%" }}>R2</th>
                        <th style={{ width: "14%" }}>Blue Zone</th>
                        <th style={{ width: "14%" }}>S1</th>
                        <th style={{ width: "14%" }}>S2</th>

                        <th style={{ width: "15%" }}>Break Down</th>
                      </tr>
                    </thead>
                    <tbody>
                      {res?.BANKNIFTY?.map((item) => (
                        <tr key={item.id}>
                          <td style={{ fontWeight: "700" }}>
                            {item.text_main} <br></br>
                            {item.updt_time !== "0" && (
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: 500,
                                }}
                              >
                                (Last Update: {item.updt_time})
                              </span>
                            )}
                          </td>
                          <td style={{ color: "#0ac405", fontWeight: "600" }}>
                            {item.r1}
                          </td>
                          <td style={{ color: "#0ac405", fontWeight: "600" }}>
                            {item.r2}
                          </td>
                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#0ac405",
                            }}
                          >
                            {item.bluezone}
                          </td>
                          <td style={{ color: "#e50303", fontWeight: "600" }}>
                            {item.s1}
                          </td>
                          <td style={{ color: "#e50303", fontWeight: "600" }}>
                            {item.s2}
                          </td>

                          <td
                            style={{
                              color: "#fff",
                              backgroundColor: "#e50303",
                              fontWeight: "600",
                              fontWeight: "600",
                            }}
                          >
                            {item.breakdown}
                          </td>
                        </tr>
                      ))}
                      {/* Add more rows here if needed */}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div
              className="nifty_banknifty_levels"
              style={{ marginTop: "25px" }}
              dangerouslySetInnerHTML={{ __html: res?.NIFTY_NOTES }}
            ></div>
          </div>
          <TrackRecord
            textOnLeft={false}
            heading={
              <>
                Analysis That Can Bring{" "}
                <HighlightedText>HappyNess</HighlightedText>
              </>
            }
            description={
              <>
                <p style={{ color: "#000" }}>
                  {" "}
                  <br />
                  To keep traders informed about critical upcoming values
                  following a major market move, we update the next Critical
                  Values as Per the Technical Analysis for a particular Time
                  Frame as indicated in the Table Above.
                </p>
                <br />
                <p style={{ color: "#000" }}>
                  <BoldTag>1. Understanding Key Parameters:</BoldTag> While the
                  S1, S2, R1, R2 stands for Nearest Supports and Resistances,
                  the Blue Zone indicates that the next resistance level might
                  be relatively distant, while the Break Down Zone suggests that
                  the subsequent support level could also be a bit far off. The
                  time frame specified in the table emphasizes that these values
                  should only be considered upon candle closing.
                  <br />
                  <br />
                  <BoldTag>2. Scrutinizing Strategy Mechanics: </BoldTag>These
                  values are calculated based on Pivots, and our other analysis
                  process based on our research and years of experience. Even
                  then, these values should be taken as indicative and NO TRADE
                  SHOULD BE INITIATED BASED ON THESE VALUES.
                  <br />
                  <br />
                  <BoldTag>3. General Advice:</BoldTag> Remember, a
                  well-prepared trader is a confident trader. Don't let your
                  hard-earned money become a gamble. Analyze strategies with the
                  same rigor you'd apply to any major purchase.
                  <br />
                  <br />
                  <BoldTag>These values serve a dual purpose: </BoldTag> They
                  bolster your confidence in technical analysis and our research
                  while sharpening your ability to differentiate between trading
                  and investment. This clarity keeps you consistently aligned
                  with the market's movements and on the right track.
                  <br />
                  <br />
                </p>
                <br />
                {/* <BoldTag>Exam Pattern of About Strategy</BoldTag>
              <p>
                The Goethe-Zertifikat A1: About Strategy exam comprises of 4
                sections -{" "}
                <HighlightedText>
                  reading, listening, writing and speaking
                </HighlightedText>{" "}
                sections. The speaking section of the exam is completed in a
                group. The exam is{" "}
                <BoldTag>
                  administered and evaluated in the same way all over the world
                </BoldTag>
                .
              </p> */}
              </>
            }
            imageSrc={technicalcomparison}
            subheading=" How to Use These Technical Analysis Values"
          />
          <TrackRecord
            className="trackRecordContainer"
            textOnLeft={true}
            heading={
              <>
                General Info About{" "}
                <HighlightedText>Risks Involved </HighlightedText>
              </>
            }
            description={
              <>
                <p style={{ color: "#000" }}>
                  {" "}
                  <BoldTag>Market Risks</BoldTag> - Market risk refers to the
                  potential for sudden and significant changes in financial
                  markets due to major events, like central bank interventions.
                  These events can lead to rapid and substantial fluctuations in
                  currency values, potentially resulting in stop-outs or
                  negative balances. For instance, extreme occurrences such as
                  crude oil prices dropping below zero highlight the
                  unpredictability and potential risks in financial markets.
                </p>
                <br />
                <p style={{ color: "#000" }}>
                  {" "}
                  <BoldTag>Broker Selection</BoldTag> -Choosing a broker is
                  crucial. If the follower's broker reaches its maximum open
                  trades limit, it can lead to malfunctions on certain trading
                  platforms. Moreover, if broker servers are unresponsive,
                  trades may not execute at all. It's important to note that
                  each broker has its own maximum trades per minute and
                  execution speed, adding another layer of complexity to the
                  decision-making process.
                </p>
                <br />
                <p style={{ color: "#000" }}>
                  {" "}
                  <BoldTag>DISCLAIMER</BoldTag>
                  <br /> The content on this website, including opinions, news,
                  research, analyses, prices, and other information, is offered
                  as general market commentary and should not be considered
                  investment advice. We do not take responsibility for any
                  losses incurred from investments made based on
                  recommendations, forecasts, or other information provided
                  here.
                  <br />
                  <br />
                  <BoldTag>
                    Happyness Pro is not liable to any losses occurred due to
                    any reason whatsoever due to our platform.
                  </BoldTag>
                </p>
                <br />
              </>
            }
            imageSrc={coursedetail}
            subheading=" Profits Depends on Conviction: Master Risks to Realise Profits"
          />
        </div>
        <button id="download" style={{ display: "none" }}></button>
      </div>
    </>
  );
};

export default NiftyIntradayTable;
