import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/IiflTradingAccount.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import demat from "images/demat.png";
import gols from "images/goals.png";
import basicstrategy from "images/basicstrategy.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 font-black`;
const Title = tw.h2`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight mt-8`;
const Gtext = tw.span`text-blue-900 font-bold`;
const Htext = tw.span`text-gray-400 font-bold text-4xl`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {

  return (
    <>
      <Helmet
        title="Happyness Pro - Your Trading Partner"
        meta={[
          {
            name: "description",
            content:
              " Here's why IIFL Demat & Trading Account is Best for You.",
          },
          {
            property: `og:title`,
            content: "Happyness Pro - Your Trading Partner",
          },
          {
            property: `og:description`,
            content: "Take the first step of your investment journey with us.",
          },
          {
            property: `og:image`,
            content: {demat},
          },
          {
            property: `og:image:alt`,
            content: "HappyNess About Strategy",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"https://happyness.pro/iifl-trading-account",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />

      <AnimationRevealPage>
        <MainBanner
          roundedHeaderButton={true}
          mainTitle={
            <Title>
              Here's why <Gtext>IIFL Demat & Trading Account</Gtext> is Best for You.
            </Title>
          }
          subTitle="<b>Take the first step of your investment journey with us.</b><br/><br/><p style=font-weight:600;padding-bottom:15px;>&#8658; Auto Integration with HappyNess Pro Strategies</p><p style=font-weight:600;padding-bottom:15px;>&#8658; Buy Now Pay Later Auto Activated for Equities</p><p style=font-weight:600;padding-bottom:15px;>&#8658;  Invest With Greater Flexibility - Lower Margin Requirements</p><p style=font-weight:600;padding-bottom:15px;>&#8658; Zero Annual Charges*</p><p style=font-weight:600;padding-bottom:15px;>&#8658; Brokerage Plans from Rs. 3 Per Lot Onwards *</p><p style=font-weight:600;padding-bottom:15px;>&#8658; User Friendly Latest IIFL Trading Apps</p><p style=font-weight:600;padding-bottom:15px;>&#8658; Zero Client Hassles (Downtime)</p><p style=font-weight:600;padding-bottom:15px;font-size:16px> Terms & Conditions Apply**</p>"
          buttonText="Let's Talk"
          buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to know more About Rule Based Trading"
          imageSrc={demat}
          
          showClient={false}
          text="About Strategy"
          knowmoreUrl={innerPages.contactusforaccountopening.url}
        />
        <TrackRecord
          textOnLeft={false}
          heading={
            <>
              Basics About {" "}
              <HighlightedText>Strategy</HighlightedText>
            </>
          }
          description={
            <>
              <p style={{color:"#000"}}>
                {" "}
                Just like you wouldn't buy a car without considering factors like resale value, mileage, and ownership costs, you shouldn't deploy a <BoldTag>trading strategy</BoldTag> without a thorough analysis.<br/><br/>The financial markets can be unforgiving, and transparency is key.  Here's what separates informed traders from those left in the dust:
              </p>
              <br />
              <p style={{color:"#000"}}>
                <BoldTag>1. Understanding Key Parameters:</BoldTag> Minimum capital requirements, maximum historical drawdown (peak-to-trough decline), and profit factor (a measure of profitability) are crucial data points.<br/><br/>
                <BoldTag>2. Scrutinizing Strategy Mechanics: </BoldTag> Delve into the core concept (technical analysis, price patterns, etc.) and ensure clear entry and exit rules are defined.<br/><br/>
                <BoldTag>3. Demanding Proof of Profitability:</BoldTag> Look for a strategy with a demonstrably successful track record through historical data or backtesting.<br/><br/>
                Remember, a well-prepared trader is a confident trader. Don't let your hard-earned money become a gamble. Analyze strategies with the same rigor you'd apply to any major purchase.
              </p>
              <br />
            
            </>
          }
          imageSrc={basicstrategy}
          subheading="Steps for Analysing a Strategy Before Deployment"
        />
        {/* <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={true}
          imageSrc={gols}
          steps={[
            {
              mainIcon: "",
              heading: "Understand the Market Risks - ",
              description:
                "Should be mentally prepared for Market Unexpected Behaviour.",
            },

            {
              mainIcon: "",
              heading: "Are Looking for a Second Income -",
              description:
                "Algo Trading can be deployed on Small Amounts.",
            },

            {
              mainIcon: "",
              heading: "Have Patience & Conviction -",
              description:
                " Both the qualities are the result of confidence and knowledge of Algo Trading and its Basics",
            },
          ]}
        />

        <Faqs
          textOnLeft={false}
          buttonUrl="https://wa.me/918920403902/?text=Hi, I need more info on Fit in Deustch Exam ..."
          buttonText="Lets Connect"
          description="Here are some frequently asked questions about Goethe-Zertifikat A1: About Strategy Exam, feel free to reach out in case of we missed out something."
          faqs={[
            {
              question:
                "What are the Eligibility / Requirements of this Exam ??",
              answer:
                "<p>The Goethe-Zertifikat A1: About Strategy is a German exam for children and young people between 10 and 16 years of age.</p><p>The Goethe-Institut exams are available to all interested parties and can be taken regardless of age and nationality.</p><p>To sit the Goethe-Zertifikat A1: About Strategy exam, candidates must have German language skills corresponding to the first level of competence (A1) of the Common European Framework of Reference for Languages (CFR).</p>",
            },
            {
              question: "How is Speaking Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to introduce yourself, ask questions on everyday matters and answer simple questions. You will also be required to make requests and respond to your conversation partner in situations with which you are familiar.</p><p><b>Duration of the Speaking Ability Test is 15 Mins</b></p>",
            },
            {
              question: "How is Listening Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to listen to short everyday conversations, personal telephone messages, information on the radio and similar recordings and complete various exercises on what you have heard.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Reading Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to read texts such as brief notes, classified advertisements, descriptions of people, simple letters to the editor or short newspaper articles and complete exercises on these texts.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Writing Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to reply to an email, letter, advertisement or similar piece of correspondence.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
          ]}
        /> */}
        {/* <Testimonials
          subheading="Learn German from the Best Institute in India"
          heading="Our Students Love Us."
          description={
            <>
              <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is
              that we work with you from start to finish. We pride ourselves in
              being fully engaged with your german learning journey, to help you
              open global opportunities for education and career growth.
            </>
          }
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
          textOnLeft={true}
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
              heading:
                "Didn’t Expected Language Learning can be So Interesting & Engaging",
              quote:
                "My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
              customerName: "Showick Thorpe",
              customerTitle: "Founder, V&S Thorpes Education Inc.",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
              heading: "Love the Teaching Experience and Technology Tools",
              quote:
                "I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
              customerName: "Amit Chawla",
              customerTitle: "FCA, Content Creator at EY",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
              heading: "Individualized Attention & Effective Learning",
              quote:
                "We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
              customerName: "Achal Gupta",
              customerTitle: "CEO, Kiran Prakashan",
            },
          ]}
        /> */}
        {/* <FastestGrowing /> */}
        <PreFooter
          fromPage="About Strategy"
          formheading="Kickstart your journey with rule based trading here."
          formsubheading="Book Our Free Discussion Session Today - "
          heightlightText="The Benefits of Using a Structured Content Distribution Platform"
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
