import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/aurumlogin.png";
import logo from "images/Proffie-logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import {serverUtilities} from '../services/serverutilities.service';

const Container = tw(ContainerBase)`min-h-screen bg-white text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900  flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto my-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 w-full py-4 rounded-lg hover:bg-brand-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-5 xl:m-5 w-full max-w-full bg-contain bg-center bg-no-repeat`}
`;
const ErrorLabel = tw.p`text-red-700 text-sm mt-5`;

// const [inputField, setInputField] = useStates(null)
export default ()=>{
  let logoLinkUrl = "/";
  let illustrationImageSrc = illustration;
  let headingText = "User Sign In";
  let submitButtonText = "Sign In";
  let SubmitButtonIcon = LoginIcon;
  let forgotPasswordUrl = "#";
  let signupUrl = "#";
  let fields = {}
  const [msgVal, setError] = useState('');

  let name = "adminLoginData";
  var date = new Date();
  let value = "expired";
  date.setTime(date.getTime() - (6*60*60*1000));
  let expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  const [step, setStep] = useState(1); // 1: Phone, 2: OTP, 3: Additional Info
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [name1, setName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  
  const handleInputChange = (e) => {
    setPhone(e.target.value);
  };

  const handleOtpChange = (index, value) => {
    // Update the corresponding OTP digit
    const newOtp = [...otp];
    newOtp[index] = value;

    // Move focus to the next input box
    if (index < 3 && value !== "") {
      document.getElementById(`otp${index + 1}`).focus();
    }

    setOtp(newOtp);
  };


  const validatePhoneNumber = () => {
    
    const numericRegex = /^[0-9]+$/;
    
    if (!phone.trim() || !numericRegex.test(phone) || phone.length !== 10) {
      setErrorMsg("Please enter a valid 10-digit numeric phone number.");
      return false;
    }else{
      setErrorMsg('')
      setStep(2)
    }

    
  };


  const handleOtpVerification = (e) => {
    e.preventDefault();

    
    const isOtpValid = otp.every((digit) => digit !== "");

    if (!isOtpValid) {
      setErrorMsg("Please fill in all four OTP fields.");
      return false;
    }else{
      setErrorMsg('')
      setStep(3);

    }

    const enteredOtp = otp.join("");
   
  };

  const handleAdditionalInfoSubmission = (e) => {
    e.preventDefault();

    
    if (!name1.trim() || !email.trim()) {
      setErrorMsg("Please fill in both name and email fields.");
      return;
    }

  };
  const handleChange = (e) => {
    console.log(e.target.name);
    fields[e.target.name] = e.target.value; 
  }
  const sendLogin = (e) => {
    console.log(fields);
    e.preventDefault();
    console.log("I am clicked");
    fields['username'] = document.getElementById('username').value;
    fields['password'] = document.getElementById('password').value;
    if(fields.username == ''){
      setError("Please Enter Email Id")
      return;
    }
    if(fields.password == ''){
      setError("Please Enter Password");
      return;
    }
    console.log(fields.username);
    serverUtilities.login('email',fields.username,fields.password).then(loginData=>{
      console.log(loginData);
      if(loginData.status == '0'){

        setError(loginData.message);
        
      }
      else
      {
        let value = loginData.cookieData;
        let name = "adminLoginData";
        var date = new Date();
        date.setTime(date.getTime() + (6*60*60*1000));
        let expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        window.location.href = loginData.url; //:w"https://aurumlabs.co/onlinelearning/admin/login";
      }
    });
  }

 
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
          <LogoLink href="/">
              <LogoImage src={logo} className="aurum-logo" style={{ width: 'auto', height: '120px' }} />
            </LogoLink>
            <MainContent>
              <Heading>{step === 1 ? "Enter Phone Number" : step === 2 ? "Verify OTP" : "Additional Information"}</Heading>
              {errorMsg !== "" && <ErrorLabel>{errorMsg}</ErrorLabel>}
              <FormContainer>
                {step === 1 && (
                  <Form>
                    <Input
                      type="tel"
                      pattern="[0-9]{10}"
                      required
                      placeholder="Phone Number"
                      value={phone}
                      onChange={handleInputChange}
                    />
                    <SubmitButton type="submit" onClick={() => validatePhoneNumber()}>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">Send OTP</span>
                    </SubmitButton>
                  </Form>
                )}
                {step === 2 && (
                  <Form>
                    <div tw="flex">
                      {otp.map((digit, index) => (
                        <Input
                          key={index}
                          type="text"
                          pattern="[0-9]"
                          maxLength="1"
                          required
                          id={`otp${index}`}
                          value={digit}
                          onChange={(e) => handleOtpChange(index, e.target.value)}
                          style={{
                            flex: 1,
                            height: '50px',
                            width: '50px', 
                            margin: '0 5px', 
                          }}
                        />
                      ))}
                    </div>
                    <SubmitButton type="submit" onClick={handleOtpVerification}>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">Verify OTP</span>
                    </SubmitButton>
                  </Form>
                )}
                {step === 3 && (
                  <Form>
                    <Input
                      type="email"
                      required
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      type="text"
                      required
                      placeholder="Name"
                      value={name1}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <SubmitButton type="submit" onClick={handleAdditionalInfoSubmission}>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">Submit</span>
                    </SubmitButton>
                  </Form>
                )}
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};