import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import IgpLogo from "../../images/clientLogos/igp.png";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import Links from "components/links/Links.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import {serverUtilities} from '../../services/serverutilities.service';

const HeadingRow = tw.div`flex`;
const ClientLink=tw.div`mt-12 lg:pl-10`;
const TwoColumnImage = styled.div`
  ${tw`grid grid-cols-2 gap-1 mt-5`}
  img{
    ${tw``}
  }
  `;
const Heading = tw(SectionHeading)`text-gray-900 lg:mb-10 lg:m-auto lg:text-lg text-xs pl-2`;
const MainPage=tw.div`flex flex-col px-4 lg:flex-row lg:px-0`;
const Lpblog= tw.div`w-full lg:w-4/5`;
const Summary= tw.div`bg-gray-200 px-5 pt-2 rounded-xl mt-4 pb-4`;
const Img= tw.img` lg:w-40 w-20`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
   span {
    ${tw`text-lg mt-2 mb-2 leading-loose text-purple-800 font-semibold italic text-center`}
  }
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

//flower-design-925-sterling-silver-toe-ring-190
export default ({ headingText = "Case Study-IGP" }) => {
   let  url  = useParams();
   console.log(url);
   let slug = url.name;
  useEffect(() => {
    serverUtilities.getBlog("flower-design-925-sterling-silver-toe-ring-190").then(loginData=>{
      console.log(loginData);
    });
  }, []);
  const getblogData = () => {
   
    
  }
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <MainPage>
          <Lpblog>
          <HeadingRow>
            <Img src={IgpLogo} alt="Igp"/>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <Summary>
            <h3>Summary </h3>
            <p>
               Established in the year 2000, IGP is an unparalleled, top-tier commerce institute wherein company only impart knowledge to young aspirants but also believe in delivering complete holistic development to all the students which they polish and groom professionally. IGP, An Institute for Grooming Professionals par excellence in the various fields such as CA, CMS & CMA Coaching. IGP is backed with brilliantly skilled and highly astute teachers thereby envisage to impart utmost superior and a high quality coaching to all such students for the different rigorous levels of professional courses such as CA Foundation, CA Intermediate and CA Final, CS Foundation, CS Executive and CS Final and also CMA Foundation, CMA Intermediate and CMA Final.
            </p>
            
            <p>
               In 2020, the institute was looking to refresh their existing system of offering classes to new way of e-learning module.  
            </p>
            </Summary>
            <h3>Key Challenges</h3>
            <ul>
              <li>
                <p>
                  Lack of information to develop Desktop/Laptop Application.
                </p>
              </li>
              <li>
                <p>
                  Inadequate alignment of projects with the IGP.
                </p>
              </li>
              <li>
                <p>
                   Poor system integration, making it difficult to access reliable project management and budget data Project Objectives.
                </p>
              </li>
              <li>
                <p>
                   Create one integrated solution to support well-structured and fully secured Live and recorded video.
                </p>
              </li>
              <li>
                <p>
                   Enable view control services for long classes on the desktop.
                </p>
              </li>

                <span>
                  “When you’re really using applications to the edge like this, you need to have a partner like aurum labs who knows how to go there.”
                </span>
                <br/>
                <span> Mr. _____, Owner, IGP Institute </span>

                <p>
                  Aurumlabs took over the organization’s technology in the year of 2020 and immediately helped develop a strategic plan to consolidate their system and migrate it to the e-learning platform.
                </p>
            </ul>
           
            <h3>A Complex Set of Requirements</h3>
            <p>
                To optimize business performance, IGP management team needed more insight into the various fields under way across the organization. “We needed one integrated application to support well-structured and systematic classes,” says Mr.. “
            </p>

            <p>
                At the same time, they needed the solution to help them measure their return on project investment. “We wanted to know whether or not we actually received the business benefits we expected from our projects,” 
            </p>

            <h3>Aurumlabs – The Right Choice</h3>
            <p>
               IGP institute considered its options carefully, evaluating all the best option available in the market before choosing Aurumlabs as its solution provider.
            </p>

            <p>
                “We even considered developing a new custom application internally, but Aurumlabs was already a preferred strategic provider for online platform, and they offered a very strong and integrated application for remote learning.  
            </p>

            <p>
                AurumLabs was responsible from blueprint to design to realization. We were responsible for developing a software to transfer offline teaching into online classes.
            </p>

            <ul >

              <li>
                <p>
                   Set up, manage, and track all basic project management tasks, including resources, schedules, and budgets.
                </p>
              </li>
              <li>
                <p>
                  Plan the project portfolio using planning tools that help decision makers determine where to focus now and in the future. 
                </p>
              </li>
              <li>
                <p>
                   Execute the project portfolio using decision-support tools that enable online classes in a much better way.
                </p>
              </li>
              <li>
                <p>
                   Monitor major portfolio performance using tools that measure how well projects are going and whether the business is achieving the expected business results.
                </p>
              </li>

            </ul>

            <h2>RESULTS</h2>
            <p>
              Now that the development has been completed, the staff’s experience has been positively impacted by the firm’s migration to the new desktop/laptop application. They now have the flexibility to offer face to face classes, virtual classes and video lectures online with fully secured tool for long classes.
            </p>

            <TwoColumnImage>
              <img src={IgpLogo} alt="Igp"/>
              <img src={IgpLogo} alt="Igp"/>
            </TwoColumnImage>

           </Text>
          </Lpblog>
          <ClientLink>
           <Links />
          </ClientLink>
         </MainPage>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
