import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import StepsImage from "images/working_steps.png";
import FastImg from "images/Fast.png";
import FocusedImg from "images/Focused.png";
import LearningImg from "images/Personalized.png";
import AccessImg from "images/access.png";


const Container = tw.div`relative bg-blue-900 items-center`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto md:pb-24 md:pt-0`}
`;
const Heading = tw(SectionHeading)`w-full mb-20`;
const Textheading = tw(Subheading)`text-center pt-12 underline text-lg font-bold text-[#35f7f5]`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left lg:w-1/3 h-full`}
  .featureIcon {
    ${tw`sm:mx-auto lg:mx-0`}
  }
  .points{
    ${tw`px-10 text-center mb-5 lg:px-0 lg:text-left`}
  }

  .working_steps{
    ${tw`text-[56px] opacity-50 text-[#35f7f5]`}
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-semibold text-2xl leading-none block text-white`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-normal text-black leading-6 text-white`}
  }
  .mobImg{
    ${tw`mb-5 w-full text-center`}
    img{
      ${tw`mx-auto`}
    }
  }
`;


export default ({ heading = "Total HappyNess = Easy to Deploy + Complete Transparency + Small Capital + Low Cost", subheading = "How It Works" }) => {
 

  return (
    <Container className="steps">
      <Textheading>{subheading}</Textheading>
      <ThreeColumnContainer>
        <Heading style={{color:"#fff"}}>{heading}</Heading>
        <Card>
          <div>
            <div className="points">
              <h1 className="working_steps">01</h1>
              <h3 className="title">Discuss with Our Experts</h3>
              <p className="description">Let our experienced managers help you select the best strategy based on available capital, trade and risk temperament. Consistency and Confidence is the key, which is gained with knowledge and discussion.</p>
            </div>
            <div className="points">
              <h1 className="working_steps">02</h1>
              <h3 className="title">Prepare Yourself</h3>
              <p className="description">Follow the 3 steps above to Understand the Basics, Analysing Backtesting and then Start Paper Trading on strategy selected in step 1</p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="mobImg">
            <img src={StepsImage} alt="" />
          </div>
        </Card>
        <Card>
          <div>
          <div className="points">
              <h1 className="working_steps">03</h1>
              <h3 className="title"> Subscribe & Go</h3>
              <p className="description">Subscribe to our flexible plans starting from Rs. 799/- per month, Add funds & connect your broker account. You are now Good to Go.</p>
            </div>
            <div className="points">
              <h1 className="working_steps" style={{marginTop:"70px"}}>04</h1>
              <h3 className="title">Complete Flexibility & Tracking</h3>
              <p className="description">Our team will enable your User Console with various features, reports and provision for Paper Trading.</p>
            </div>
          </div>
        </Card>
      </ThreeColumnContainer>
    </Container>
  );
};
