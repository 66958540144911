import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { components } from "ComponentRenderer.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-10 w-10 bg-cover bg-center rounded-t-lg sm:ml-8 sm:mt-5`}
`;
const Info = tw.div`p-8`;
const Category = tw.div`capitalize text-black text-xl font-bold tracking-widest leading-loose after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic text-xs`;
const Title = tw.div`mt-3 text-lg text-blue-500`;
const Description = tw.div``;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const HeadingNN = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const DescriptionNN = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-200`
const TextColumn = styled(Column)(props => [
  tw`md:w-full md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  headingText = "Legal & Policy Center",
  subheading = "Contact Us",
  headingNN = <><span tw="text-gray-900 font-bold">Legal & Policy Center</span><wbr/></>,
  descriptionNN = "Our legal and policy center offers a single convenient destination to access resources that govern your relationship with us, and your use of Aurum's products and services. Stay up to date by checking back on a regular basis, as amendments may be made from time to time.",
  textOnLeft = true,
  posts = [
    {
      imageSrc:
        "https://youngminds.pro/favicon.ico",
      category: "How you can Use Our Services",
      title1: "Terms of Service for User",
      url1: innerPages.termsofservicepage.url,
      title2: "Generat Terms & Conditions",
      url2: innerPages.generaltermsandconditions.url
      
    },
    {
      imageSrc:
        "https://youngminds.pro/favicon.ico",
      category: "How we handle your data",
      title1: "Our Privacy Policy",
      url1: innerPages.privacypolicypage.url,
    },

    // getPlaceholderPost(),
 
  ]
}) => {
  const [visible, setVisible] = useState(7);
  
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              
              <HeadingNN>{headingNN}</HeadingNN>
              <DescriptionNN>{descriptionNN}</DescriptionNN>
            </TextContent>
          </TextColumn>
         
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    {post.title1 && <a href={post.url1} ><Title>{post.title1}</Title> </a>}
                    {post.title2 && <a href={post.url2} ><Title>{post.title2}</Title> </a>}
                    {post.title3 && <a href={post.url3} ><Title>{post.title3}</Title> </a>}
                    {post.title4 && <a href={post.url4} ><Title>{post.title4}</Title> </a>}
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Travel Guide",
  date: "April 19, 2020",
  title: "Visit the beautiful Alps in Switzerland",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://reddit.com"
});
