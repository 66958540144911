import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { Helmet } from 'react-helmet';
export default () => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const TITLE = 'Young Minds Unique German Language Pedagogy';
  const tagNameD = 'Simple, Laser Focused and Flexible Teaching Methodology that allows every one to Learn German Language Easily.';
  const Title = tw.h1`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
  const Gtext=tw.span`text-blue-900 font-bold`;
  const Ltext=tw.span`text-brand-700 font-bold`;
  return (
    <>
      <Helmet
      
      title={TITLE}
      
      meta={[
        {
          name: 'description',
          content: {tagNameD},
        },
        {
          property: `og:title`,
          content: {TITLE},
        },
        {
          property: `og:description`,
          content: {tagNameD},
        }]}
    />
      <Hero roundedHeaderButton={true} 
        mainTitle={<Title>Discover the Power of <Gtext>German</Gtext> Language</Title>} 
        subTitle="Expand Your Horizons, Boost Your Career, and Prepare for a Global Outreach right from School Days"
        buttonText="Lets Go"
        buttonUrl="https://wa.me/918920403902/?text=Hi, We would like to explore German opportunities"
        imageSrc="https://aufiles.aurumlabs.co/custom_apps_main.png"
        showClient= {false}
      />
      <AnimationRevealPage>
       
        <VerticalSeprator className="v-seperator" />
        <MainFeature
          primaryButtonText="Lets Talk"
          primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, We would like to know more about Global Careers with German."
          heading="German Language - Foundation for Global Career"
          description="German language proficiency can be an excellent starting point for pursuing a study abroad experience or a global career for several reasons :<p>1. Germany has some of the world's best universities, offering a wide range of programs in various disciplines. German language proficiency can give you access to top-notch academic institutions and research opportunities.</p><p>2. Germany is the economic powerhouse of Europe and has a strong presence in several industries such as automotive, engineering, and technology. Knowledge of German can give you an advantage in securing employment opportunities with German companies.</p><p>3. After Graduation in Germany, you can try to work in any company across 22 Countries of Europe.</p><p>4. <b>Education in Germany is Free</b>, but German Profeciency is Must.</p>"
          subheading="Own the Future, Take the First Step - Learn German"
          textOnLeft={false}
          imageSrc="https://aufiles.aurumlabs.co/custom_apps_brand_ux_ui.png"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, We would like to explore global career opportunities"
          heading="Prepare to Engage Globally in Simplest Way"
          description="German language proficiency can unlock a world of career opportunities for individuals. Whether you're seeking to study abroad, secure employment with German-speaking companies or engage in cross-cultural communication, a German language certificate course can provide you with valuable skills and knowledge that can propel your career prospects to new heights. <p><b>German is a vital language in the business world</b>. Germany is the largest economy in Europe and home to many world-renowned companies in various industries such as automotive, engineering, and technology. German language proficiency can increase your chances of securing employment opportunities with these companies and give you an edge over other candidates.</p><p>German language proficiency can also be an asset for students seeking to <b>study abroad in Germany</b> or other German-speaking countries. German universities are renowned for their high-quality education, and knowledge of the German language can help students integrate into the academic environment and engage with their peers and professors more effectively.</p>"
          subheading="Avail 10x Career Opportunities and Career Growth"
          imageSrc="https://aufiles.aurumlabs.co/custom_apps_engage_users.png"
          imageBorder={false}
          imageDecoratorBlob={true}
        />
        
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'German Language Proficiency - First Step to Global Career'}
          heading={
            <>
              Frequently Asked Questions 
            </>
          }
          faqs={[
            {
              question: "Can Learning German in School Help in Graduation / Post Graduation ?",
              answer:
                "German Proficiency of at least C1 Level is required for Top End German Univerisities. Learning German at school level and staying in touch will drastically reduce your learning effort and time in class 12th. We have experienced, students with basic german language knowledge during their school days clear the Goethe Certificate Exam in their First Attempt and half preparation time than others."
            },
            {
              question: "Can I study and work in Germany after graduation?",
              answer:
                "Yes, international students can apply for a residence permit to work and live in Germany after graduation. In most cases, Job Seeker Visa for 18 months is granted that is valid across 22 Countries in Europe. You can work in the company and country of your choice."
            },
            {
              question: "What are the Career Prospects in India for German Experts?",
              answer:
                "German Experts are now a days required in Business and Trade, Language Teaching in Colleges, Schools & institutions, Tourism & Hospitality Industry and Diplomacy & Cultural Exchange Programs (Translators for Government)"
            },
            {
              question: "Can I study in Germany if I don't speak German?",
              answer:
                "Yes, but there are very few programs offered in English at German universities. However, German Proficiency is Must if you aspire to study in Top End Universities and Courses."
            },
            {
              question: "How much does it cost to study in Germany?",
              answer:
                "Tuition fees at German universities are quite low, and in some cases, even free for international students. However, students need to cover their living expenses, which can vary depending on their lifestyle and location."
            },
            {
              question: "Can I work while studying in Germany?",
              answer:
                "Yes, international students are allowed to work part-time during their studies to support themselves financially. However, there are restrictions on the number of working hours, and students need to obtain a work permit."
            }
            
          ]}
        />
        <PreFooter fromPage = "Customised Apps" formheading = "Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business."/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
