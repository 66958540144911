import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as ArrowForward } from "images/arrowForward.svg";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import styled from "styled-components";

const Container = tw(ContainerBase)`lg:mt-10 bg-[#fce4f0] text-textBlack -mx-8 px-8 xs:px-16`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`font-medium text-secondary-400 text-base leading-loose text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`text-center`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`
const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`bg-blue-900 hover:bg-brand-700 hover:text-white mt-6 text-sm inline-block mx-auto md:mx-0 rounded-full text-white p-2 px-5`,
  props.buttonRounded && tw`rounded-full`
]);
const IconContainer = tw.i``;
export default ({
  primaryButtonText = "Let's Talk",
  primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, I would like to know more about German and YoungMinds",
  buttonRounded = false,
  subheading = "",
  heading = "The Fastest Growing German Learning Platform",
  description = "Easy to Build Unified and Structured Content Library, Integrations and Monetisation",
  stats = [
    {
      key: "Content Owners",
      value: "500+",
    },
    {
      key: "Engaged Users",
      value: "1M+",
    },
    {
      key: "Countries",
      value: "40+",
    },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {primaryButtonText && 
            <LinkButton buttonRounded={buttonRounded} className="exploreBtn" as="a" href={primaryButtonUrl}>
              {primaryButtonText} <IconContainer><ArrowForward className="btnArrow" /></IconContainer>
            </LinkButton>
          }
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
