import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

/*import LogoImage from "../../images/logo.svg";*/
import logo from "../../images/happy_logo4.png";
import StartupLogo from "../../images/fixed-startup-india-logo.png";
import { components } from "ComponentRenderer.js";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";

const Container = tw.div`relative bg-[#204dcf] -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 lg:flex lg:flex-row md:flex md:flex-row md:flex-wrap  xs:flex xs:flex-col`;

const Column = tw.div`mt-5 md:w-1/5 lg:w-[16%] xl:w-[15%]`;
const WideColumn = tw(Column)`text-center md:text-left lg:w-1/4 md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-white`;

const LinkList = tw.ul`mt-4 text-sm font-medium `;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent text-white hocus:text-white hocus:border-white pb-1 transition duration-300`;
const LinkB = tw.a`ml-5 border-b-2 border-transparent text-white hocus:text-white hocus:border-white pb-1 transition duration-300 first:ml-0 `;
const LinkC = tw.a`border-b-2 border-transparent text-white hocus:text-blackStep-100 hocus:border-blackStep-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const AlIngenious= tw.h1`mt-2 text-lg font-bold text-white`;
const LogoImg = tw.img`w-1/2`;
const StartupImg = tw.img`w-1/2 py-4`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-white`;

const SocialLinksContainer = tw.div`mt-4 ml-8 md:ml-0 lg:ml-0`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-[#000] text-gray-100 hover:bg-gray-900 transition duration-300 mr-8`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
}) => {

  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={logo}  className="footer-logo" />
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/showcase/happyness-pro/" target="_blank">
              <LinkedinIcon />
            </SocialLink>
            {/* <SocialLink href="https://www.linkedin.com/showcase/young-minds-pro/">
              <FacebookIcon />
            </SocialLink> */}
            <SocialLink href="https://twitter.com/ProHappyness" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@HappynessPro" target="_blank">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CompanyDescription>
            <h6>204, NDM 2, Netaji Subhash Place, Pitam Pura, New Delhi - 110034</h6>
            
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Algo Trading</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={innerPages.aboutrulebasedalgotrading.url}>About Strategies</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.howtodeploystrategyinyourtradingaccount.url}>How to Deploy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.contactus.url}>Contact Us</Link>
            </LinkListItem>
            {/* <LinkListItem>
              <Link href={innerPages.germana2.url}>German A2</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://youngminds.pro/study-abroad-in-germany/study-in-germany">Study in Germany</Link>
            </LinkListItem> */}
          
          {/* <LinkListItem>
              <Link href={innerPages.prospectsofgermanlanguage.url}>Prospects of German</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Services</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={innerPages.pinescript.url}>PineScript Coding</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.contactus.url}>Technical Analysis Report</Link>
            </LinkListItem>
            
            {/* <LinkListItem>
              <Link href={innerPages.germanlanguageforclass10.url}>Blog</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.germanlanguageforclass11.url}>for Class 11</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.germanlanguageforclass12.url}>for Class 12</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>What's Up</ColumnHeading>
          <LinkList>
            <LinkListItem>
               <Link href={innerPages.latestupdates.url}>Latest Updates</Link>
            </LinkListItem>
            <LinkListItem>
               <Link href={innerPages.learnershub.url}>Learner's Hub</Link>
            </LinkListItem>
            {/* <LinkListItem>
              <Link href={innerPages.blog.url}>Blogs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.contactus.url}>Contact Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.policycenter.url}>Policy Center</Link>
            </LinkListItem> */}

          </LinkList>
        </Column>
        <CompanyDescription>
         <ColumnHeading>Argentum Labs Private Limited</ColumnHeading>
          <StartupImg src={StartupLogo} className="" />
          DEPARTMENT FOR PROMOTION OF INDUSTRY AND INTERNAL TRADE <br/> MINISTRY OF COMMERCE AND INDUSTRY GOVERNMENT OF INDIA <br/><br/> Registration No. DIPP97530
        </CompanyDescription>
      </FiveColumns>
    </Container>
  );
};
