import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Media from "components/media/Media.js";
export default ({
}) => { 
  const msgColor ={color:"#333"};
  return (
    <AnimationRevealPage>
      <Header />
      <Media />       
      <Footer />
    </AnimationRevealPage>
  );
};
