import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/TwoColumnWithInput.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import careers from "images/careers.png";
import { ReactComponent as ArrowRightIcon } from "../images/arrow-right-icon.svg";
import { ReactComponent as Checkicon } from "../images/check.svg";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";


const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`font-bold text-black`;
const PositionSection = tw.div`lg:px-30 lg:py-30 lg:justify-center bg-baseGray xs:px-5 xs:py-5 xs:justify-center`;
const LeftText = tw.span`text-black lg:text-3xl font-bold xs:text-2xl`;
const ListItemsInner = tw.div`bg-white w-full px-10 xs:px-5 py-5 mt-12`;
const ListBorder = tw.div`lg:flex lg:flex-row border-b-2 border-gray-200 w-full py-3 xs:flex-col`;
const LeftInner = tw.div`lg:flex lg:flex-row lg:w-full lg:pr-20`;
const ListName = tw.span`text-sm mr-30 font-bold xs:text-sm xs:mr-0 xs:inline-block xs:w-full xs:mb-4`;
const Description = tw.span`text-gray-600 text-sm xs:mb-4`;
const RightInner = tw.div`lg:flex lg:flex-row lg:w-full `;
const ListDetail = tw.p`text-gray-600 text-sm lg:ml-30 xs:ml-0 xs:inline-block xs:w-full xs:mb-4 xs:mt-2 lg:mt-0`;
const ListIcon = styled.a`
    ${tw`cursor-pointer xs:inline-block xs:w-full lg:w-full`}
`;
const ListAction = tw(PrimaryButtonBase)`lg:float-right xs:float-left`;
export default ({
 highlightedtext = "Benefits and perks",
 heading = "Easy to",
 RightIcon = ArrowRightIcon,
 buttonText = "Send Resume",
 CheckIcon = Checkicon,
}) => { 
  return (
    <AnimationRevealPage>
      <Header />
      <FeatureWithSteps
        heading={
          <>
            <HighlightedText>{highlightedtext}</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={careers}
        steps={
            [
              { 
                mainIcon:<CheckIcon/>,
                heading: "",
                description: "Unlimited Vacation and Flexible Schedules" 
              },

              {
                mainIcon:<CheckIcon/>,
                heading:"",
                description: "$800 USD for Home-Office Set up"
              },

              {  
                mainIcon:<CheckIcon/>,
                heading: "",
                description: "$1500 USD for Learning & Development"
              }
            ]
          }
      />
      <PositionSection>
        <LeftText>
          Latest open positions
        </LeftText>
        <ListItemsInner>
          <ListBorder>
            <LeftInner>
              <ListName>
                Account Executive (AMER - East)
              </ListName>
              <Description>
                Sales
              </Description>
            </LeftInner>
            <RightInner>
              <ListDetail>
                Remote Americas (East)
              </ListDetail>
              <ListIcon href="/">
                <ListAction>{buttonText}</ListAction>
              </ListIcon>
            </RightInner>
          </ListBorder>
          <ListBorder>
            <LeftInner>
              <ListName>
                Account Executive (AMER - East)
              </ListName>
              <Description>
                Sales
              </Description>
            </LeftInner>
            <RightInner>
              <ListDetail>
                Remote Americas (East)
              </ListDetail>
              <ListIcon href="/">
                <ListAction>{buttonText}</ListAction>
              </ListIcon>
            </RightInner>
          </ListBorder>
          <ListBorder>
            <LeftInner>
              <ListName>
                Account Executive (AMER - East)
              </ListName>
              <Description>
                Sales
              </Description>
            </LeftInner>
            <RightInner>
              <ListDetail>
                Remote Americas (East)
              </ListDetail>
              <ListIcon href="/">
                <ListAction>{buttonText}</ListAction>
              </ListIcon>
            </RightInner>
          </ListBorder>
        </ListItemsInner>
      </PositionSection>
      <Footer />
    </AnimationRevealPage>
  );
};
