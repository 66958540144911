import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import LpLogo from "../../images/clientLogos/languagePantheon.png";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import Links from "components/links/Links.js";

const HeadingRow = tw.div`flex`;
const ClientLink=tw.div`mt-12 pl-10`;
const TwoColumnImage = styled.div`
  ${tw`grid grid-cols-2 gap-1 mt-5`}
  img{
    ${tw``}
  }
  `;
const Heading = tw(SectionHeading)`text-gray-900 mb-10 lg:m-auto lg:text-sm`;
const MainPage=tw.div`flex flex-col px-4 lg:flex-row lg:px-0`;
const Lpblog= tw.div`w-full lg:w-4/5`;
const Summary= tw.div`bg-gray-200 px-5 pt-2 rounded-xl mt-4 pb-4`;
const Img= tw.img` lg:w-40 `;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
   span {
    ${tw`text-lg mt-2 mb-2 leading-loose text-purple-800 font-semibold italic text-center`}
  }
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Case Study- Language Classes" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <MainPage>
          <Lpblog>
          <HeadingRow>
            <Img src={LpLogo} alt="LpLogo"/>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <Summary>
            <h3>Summary </h3>
            <p>
               Headquartered in (country) and ranked as world business leaders in the education sector, language classes wanted to optimize operational efficiency and ensure ongoing competitiveness in the e-learning platform. 
            </p>
            
            <p>
              They choose the aurumlabs to create a shared IT vision and road map across the globe.  
            </p>
            </Summary>
            <h3>Key Challenges</h3>
            <ul>
              <li>
                <p>
                  Supporting and enabling change within a dynamic business environment.
                </p>
              </li>
              <li>
                <p>
                   Creating a common vision for global delivery. 
                </p>
              </li>
              <li>
                <p>
                  Bridging the gap between today’s operational needs and future challenges.
                </p>
              </li>

                <span>
                   “The efficiencies and cost savings, that a language classes promises are exactly what we need.”
                </span>
                <br/>
                <span> “Mr.. Director of language Classes”</span>

                <p>
                  Aurumlabs was able to convey very complex details in a clear, concise way. They consistently brought in the best people they have to offer – and at just the right time and at the right price. It was a real collaborative effort that resulted in a vision and plan we believe in.”
                </p>
            </ul>
           
            <h3>The Solution</h3>
            <p>
                As per the director of Aurumlabs, our client was looking for an application that would allow him to offer online classes with content security. Aurumlabs made the application as seamless and painless as possible by outlining a defined on-boarding process, communicating thoroughly, and composing a clear plan for the company to follow throughout the changeover at a cost-effective price. With us, language classes gained global delivery with 60% cheaper than other competitors.
            </p>
            
            <span>
                 “The efficiencies and cost savings, that a language classes promises are exactly what we need.”
            </span>

            <TwoColumnImage>
              <img src={LpLogo} alt="Igp"/>
              <img src={LpLogo} alt="Igp"/>
            </TwoColumnImage>

           </Text>
          </Lpblog>
          <ClientLink>
           <Links />
          </ClientLink>
         </MainPage>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
