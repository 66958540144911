import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
import Lpimage from "../../images/clientLogos/languagePantheon.png"
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"

const Container = styled.ul`
	${tw`relative`}
	li{
		${tw``}
	}
	h3 {
    ${tw`text-xl font-bold lg:mt-24 mt-5 mb-2 px-0 `}
    }
    a {
    ${tw`text-lg font-semibold mt-12 text-blue-500`}
    }

`;


export default ({ 

}) => {
  

  return (
    <Container className="wr-clients">
    <h3>Links</h3>
    <p>
    <a href="https://languagepantheon.com/">Link-style-1-Lp</a>
    </p>
    <p>
    <a href="#">Link-style-2-Igp</a>
    </p>
    <p>
    <a href="https://kiranprakashan.com/">Link-style-3-Kp</a>
    </p>
    <p>
    <a href="#">Link-style-4-Dbmi</a>
    </p>
    <p>
    <a href="#">Link-style-5-Pj</a>
    </p>
     
    </Container>
  );
};
