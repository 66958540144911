import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Gerneral Terms And Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: April 21, 2023</p>

            <p>Please read these terms and conditions carefully before using Our Services.</p>

            <h1>Website Usage</h1>
            <p>
              By accessing or using this Site in any way, including using any Services or registering on the Site, you agree to and are bound by the terms and conditions set forth in this document and any changes thereto that Argentum Labs Pvt. Ltd. (referred to hereinafter as Argentum") may publish from time to time (collectively, the "Terms of Use"). If you do not agree to all of the terms and conditions contained in the Terms of Use, do not access or use this Site.


            </p>
            <p>
              "Materials" in this agreement refers to the video lectures and academic content served under different courses offered on the site youngminds.pro. Argentum may change the Terms of Use and other guidelines and rules posted on the Site from time to time at its sole discretion. Your continued access or use of the Site, or any Materials or Services, constitutes your acceptance of the changes. Your access and use of the Site, Materials and Services will be subject to the most current version of the Terms of Use, rules and guidelines posted on the Site at the time of such use. Please regularly check the "Terms of Use" link on the home page to view the then-current terms. If you breach any of the Terms of Use, your authorisation to access or use this Site, Services and Materials automatically terminates, and any videos/materials downloaded or printed without permission from the Argentum in violation of the Copyright Laws and Terms of Use must be immediately destroyed.
            </p>

            <h1>Registration</h1>
            <p>
              All required registration information provided must be current, complete and accurate and kept up to date on a prompt, timely basis. It is your sole responsibility to keep your user name/password combination and other sensitive information confidential. If you become aware of any unauthorised use of your account or any other breach of security, please notify Argentum immediately. If any Services or Materials provide password-restricted access to your personal information (including, but not limited to, usage records for Materials, and performance in tests and exams) then by accessing or using this site and setting up a password-restricted account for such Services or Materials, you consent to Argentum's display of such information via such Services or Materials and accept all risks of unauthorised access to such information.
            </p>
            <p>Upon termination, Your right to use the Service will cease immediately.</p>

            <h1>Intellectual Property</h1>
            <p>
              The Materials (Video Lectures and Theory Content) and Services, as well as their selection and arrangement, are protected to the fullest extent possible by applicable laws related to copyrights, trademarks, trade secrets and all other intellectual property and proprietary rights (collectively, "Intellectual Property Rights"), and any unauthorised use of the Materials or Services may violate such laws and the Terms of Use. These copyrights may belong to Argentum or to its partners who have granted Argentum the right to use their content. You agree not to copy, republish, frame, download, transmit, modify, adapt, create derivative works based on, rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer the Site, Materials or Services, any portions of the Site, Materials or Services or the selection and arrangement of the Site, Materials or Services, except as expressly authorised herein. Argentum grants you non-exclusive, non-transferable, limited permission to access and display the Web pages within this site, solely on your computer and for your personal, non-commercial use of this Web site. This permission is conditioned on your not modifying the content displayed on this site, your keeping intact all copyright, trademark, and other proprietary notices, and your acceptance of any terms, conditions, and notices accompanying the content or otherwise set forth in this site. The rights granted to you constitute a license and not a transfer of title.
            </p>
            <p>
              Use of the Argentum Materials or User Content for any other purpose is expressly prohibited and may result in severe civil and criminal penalties.
            </p>

            <h1>Notification of Copyright Infringement</h1>
            <p>
              Argentum will, in appropriate circumstances, terminate the accounts of users who infringe the Intellectual Property Rights of others. Argentum will investigate notices of copyright infringement and take appropriate actions. If you believe that your work has been used or copied in a way that constitutes copyright infringement and such infringement is occurring on this Site or in connection with the Services or Materials, please provide notification of claimed copyright infringement to helpdesk@youngminds.pro.
            </p>
            <h1>Spam e-mail and Postings</h1>
            <p>
              In the event of your or others' access to or use of the Site, Services or Materials in connection with the transmission of spam newsgroup postings or unsolicited e-mail in violation of these Terms of Use, you acknowledge and agree that Argentum would be irreparably harmed there under and that monetary damages would be an insufficient and ineffective remedy therefore you agree that Argentum is entitled to obtain immediate injunctive relief against any such transmission (in addition to all other remedies available at law or in equity). Argentum may without restriction block, filter or delete unsolicited e-mail.
              </p>
            <h1>Refund Policy</h1>

            <p>
              All sales made by Argentum will be considered final and will not be applicable for any form of refund or cancellation. In case Argentum is unable to deliver the mentioned services an appropriate refund pro-rated based on the services already delivered by Argentum will be paid to the user. 7 Day money back is applicable where ever mentioned explicitly in course details.
            </p>

            <h1>Governing Law</h1>
            <p>
              The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
              Service. Your use of the Application may also be subject to other local, state, national, or international
              laws. All legal jurisdictions subject to New Delhi, India Only.
            </p>

            <h1>Disputes Resolution</h1>
            <p>
              If You have any concern or dispute about the Service, You agree to first try to resolve the dispute
              informally by contacting the Company.
            </p>

            <h1>Indemnity and Liability</h1>
            <p>
              You agree to indemnify and hold Argentum and its officers, partners and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of: a. Your User Materials and any other content (e.g. computer viruses) that you may submit, post to or transmit through the Site (including a third party's use of such User Materials or content (e.g. reliance on the accuracy, completeness or usefulness of your User Materials)). b. Your access to or use of the Site, Services or Materials (including any use by your employees, contractors or agents and all uses of your account numbers, user names and passwords, whether or not actually or expressly authorised by you, in connection with the Site or any Services or Materials). c. Your connection to the Site, Services or Materials. d. Your violation of the Terms of Use. e. Your infringement of any third party's Intellectual Property Rights when using any of the Software made available on the Site, Services or in any Materials. f. Your violation of any rights of any third party. g. Your access to or use of Linked Sites and your connections thereto. or h. Any dealings between you and any third parties advertising or promoting via the Site, Services or Materials.
            </p>

            <h1>India Legal Compliance</h1>
            <p>
              You represent and warrant that (i) You are not located in a country that is subject to the Indian
              government embargo, or that has been designated by the Indian Government as a “terrorist
              supporting” country, and (ii) You are not listed on any Indian Government list of prohibited or
              restricted parties.
            </p>

            
            <ul>
              <li>By email: support@youngminds.pro</li>
              <li>By phone number: +91-882-662-2806</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
