import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
// import { ReactComponent as Hearing } from "images/hearing.svg";
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/kiranprakashan.png"
import DBMI from "../../images/clientLogos/dbmi.png"
import Igp from "../../images/clientLogos/igp.png"
import Hearing from "../../images/hearing.svg"
import Reading from "../../images/reading.svg"
import Speaking from "../../images/speaking.svg"
import Translate from "../../images/Translate.svg"
import Group from "../../images/group.svg"
import { SectionHeading } from "components/misc/Headings.js";
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"
const Section = styled.div`
	${tw`relative xs:px-10 py-20 md:py-24 bg-gray-300`}
`;
const Container = styled.div`
	${tw`relative text-center max-w-[1280px] mx-auto`}
	.toolSec{
		${tw`grid grid-cols-2 md:grid-cols-5 gap-y-16 gap-x-1 w-full justify-center`}
	}
	.toolitem{ 
		${tw`inline-block mr-2 w-full  mx-auto lg:max-w-[200px]`}
		.iconImg{
			${tw`w-[52px] h-[52px] rounded-full bg-blue-500 mt-[-42px] text-center p-2 mx-auto`}
			img{
				filter: invert(100%) sepia(42%) saturate(2%) hue-rotate(241deg) brightness(101%) contrast(101%);
				margin:0 auto;
			}
		}
		.iconImg.readingIc{
			${tw`p-4`}
		}
		.iconImg.speakingIc{
			img{
				${tw`w-[16px]`}
			}
		}
		a{
			${tw`block py-4 border border-blue-500 rounded-md border-opacity-25 hover:bg-blue-500/25 transition duration-300 text-xl`}
		}
	}

`;
const IconContainer = tw.i``;
const Heading = tw(SectionHeading)`w-full mb-20 max-w-[1170px] mx-auto`;

export default ({
	selectedId,
	heading = "Anytime Anywhere IMMENSE Language Practice in App/PCs",
	toolsArray = [
		{
	  		logoSrc: Hearing,
	  		href:"https://youngminds.pro/ymspeech/index.html",
	  		className:"iconImg",
	  		title: "Hearing"
	  	},
	  	{
	  		logoSrc: Reading,
	  		className:"iconImg readingIc",
	  		href:"https://v1.artamlabs.com/ymspeech/readalong",
	  		title: "Reading"
	  	},
	  	{
	  		logoSrc: Speaking,
	  		className:"iconImg speakingIc",
	  		href:"https://youngminds.pro/ymspeech/index.html",
	  		title: "Speaking"
	  	},
	  	{
	  		logoSrc: Translate,
	  		className:"iconImg",
	  		href:"https://youngminds.pro/ymspeech/aimodel/index.html",
	  		title: "Translate"
	  	},
	  	{
	  		logoSrc: Group,
	  		className:"iconImg",
	  		href:"#groupdiscussion",
	  		title: "Group Discussion"
	  	}
	  ]

}) => {
  
 const getIndex=(index)=>{
 	selectedId(index);
 }
  return (
  	<Section>
	    <Container>
	    	<Heading>{heading}</Heading>
	    	<div className="toolSec">
		      	{toolsArray.map((item, index) => (
					<div className="toolitem" onClick={()=>getIndex(index)}>
						<a href={item.href} target="_blank">
							<div className={item.className}>
								<img src={item.logoSrc} />
							</div>
							<span>{item.title}</span>
						</a>
					</div>
				))}	
			</div>
	    </Container>
	</Section>
  );
};
