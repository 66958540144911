// import React from "react";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/light.js";
// import Footer from "components/footers/SimpleFiveColumn.js";
// import MainBanner from "components/hero/YoungHeroContent.js";
// import FastestGrowing from "components/features/FastestGrowing.js";
// import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
// import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
// import Faqs from "components/faqs/SimpleWithSideImage.js";
// import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import { Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { ReactComponent as Checkicon } from "../images/check.svg";
// import ContactDetails from "components/cards/ThreeColContactDetails.js";
// import PreFooter from "components/footers/PreFooterBrand.js";
// import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
// import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
// import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
// import { components } from "ComponentRenderer.js";
// const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
// const HighlightedText = tw.span`text-blue-900 `;

// const BoldTag = styled.span`
//    ${tw`text-textBlack font-bold`}
// `;
// export default ({
//  title = "Simplified On-Boarding",
//  highlightedtext = "Get Started.",
//  heading = "Easy to",
//  CheckIcon = Checkicon,
//  innerPages = components.innerPages,
// }) => { 
//   return (
//     <AnimationRevealPage>
//       <MainBanner roundedHeaderButton={true} 
//         mainTitle="Say Hello to a Better Way to Deliver Digital Content" 
//         subTitle="Agile & Secure Platform build to Deliver Content at Enterprise Scale. Streamline and Modernise Your Digital Content Experience.Agile & Secure Platform build to Deliver Content at Enterprise Scale. Streamline and Modernise Your Digital Content Experience."
//         buttonText="Let's Talk"
//         buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
//         imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
//         showClient= {true}
//         text="Fit In Deutsch 2"
//         knowmoreUrl={innerPages.aboutfitindeutsch2.url}
//         />
//       <FeatureWithSteps
//         subheading={<SubheadingSteps>{title}</SubheadingSteps>}
//         heading={
//           <>
//             {heading} <HighlightedText>{highlightedtext}</HighlightedText>
//           </>
//         }
//         textOnLeft={false}
//         imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/easy_onboarding.gif"
//         steps={
//             [
//               { 
//                 mainIcon:"",
//                 heading: "Unify Content",
//                 description: "Manage & Structure Content from a Single Library" 
//               },

//               {
//                 mainIcon:"",
//                 heading:"Integrate",
//                 description: "Deliver Content through Aurum Apps or Integrate APIs in Custom Apps."
//               },

//               {  
//                 mainIcon:"",
//                 heading: "Ready to GO",
//                 description: "Easy to Use Console, Platform Documentations and Pay as You Go Pricing. "
//               }
//             ]
//           }
//       />
//       <TrackRecord 
//         textOnLeft={true}
//         heading={
//           <>
//             Why <HighlightedText>Aurum</HighlightedText> is the Best Choice
//           </>
//         } description = 
//           {
//             <>
//               Its the New Front Door for the Brands to Deliver Content Across Globe on Almost All Devices. Aurum makes it easy for the Content Owners and Developers to Build Faster and Manage Delivery at Scale.<p>&nbsp;</p><p>It is the easiest and fastest way to Streamline, Modernise and Monetise Content. <BoldTag>Let’s build better digital experiences together.</BoldTag></p>
//             </>

//           } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-50/why_aurum_2.png"
//         subheading = "The Modern Content Delivery & Monetisation Platform"
//       />
//       <Faqs
//         textOnLeft={false}
//         buttonUrl="https://wa.me/918920403902/?text=Hi, How can i GROW my Digital Content Business with Aurum"
//         buttonText="Lets Connect"
//       />
//       <Testimonials description = {
//         <>
//           We work with you from start to finish. We view ourselves as an extension of your team, and as your partner, we pride ourselves in being fully engaged with your company to help you find opportunities for growth. <BoldTag>The Reason why Content Owners Love Aurum</BoldTag> 
//         </>
//         } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
//         textOnLeft={true}
//       />
//       <FastestGrowing />
//       <PreFooter fromPage = "Content Delivery Platform" formheading = "Kickstart your journey with rule based trading here." formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Structured Content Distribution Platform"/>
//       <Footer />
//     </AnimationRevealPage>
//   );
// };



import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/YoungHeroContent.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import deploy from "images/deploy1.png";
import gols from "images/goals.png";
import coursedetail from "images/risk.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 font-bold`;
const Title = tw.h2`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const Gtext = tw.span`text-blue-900 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {
  return (
    <>
      <Helmet
        title="HAPPYNESS PRO - Rule Based Trading"
        meta={[
          {
            name: "description",
            content:
              "Streamline and Simplify German Language Learning for Global Career Goals",
          },
          {
            property: `og:title`,
            content: "HAPPYNESS PRO - Rule Based Trading",
          },
          {
            property: `og:description`,
            content:
              "You can easily deploy or put our strategies to action with the help of our platform and Broker API.",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/hp_logo.png",
          },
          {
            property: `og:image:alt`,
            content: "HappyNess Pro About Rule Based  Strategies",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:site_name`,
            content: "HappyNess Pro",
          },
          {
            property: `og:url`,
            content: "http://happyness.pro/how-to-deploy-strategy-in-your-trading-account",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />

      <AnimationRevealPage>
        <MainBanner
          roundedHeaderButton={true}
          mainTitle={
            <Title>
              <Gtext>Hassles Free Deployment Strategy</Gtext>
            </Title>
          }
          subTitle="You can easily deploy or put our strategies to action with the help of our platform and Broker API. It’s a simple step by step process and our team will help you and get it done without any hassles.<br/><br/>Kindly refer to the next section for more information about the risks involved. Please consult your financial advisor before investing/trading via our platform."
          buttonText="Let's Talk"
          buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to know more About Rule Based Trading"
          imageSrc={deploy}
          
          showClient={false}
          text="How To Deploy"
          knowmoreUrl={innerPages.upcomingseminar.url}
        />
        <TrackRecord
          textOnLeft={false}
          heading={
            <>
              General Info About {" "}
              <HighlightedText>Risks Involved </HighlightedText>
            </>
          }
          description={
            <>
              <p style={{color:"#000"}}>
                {" "}
                <BoldTag>Market Risks</BoldTag> - Market risk refers to the potential for sudden and significant changes in financial markets due to major events, like central bank interventions. These events can lead to rapid and substantial fluctuations in currency values, potentially resulting in stop-outs or negative balances. For instance, extreme occurrences such as crude oil prices dropping below zero highlight the unpredictability and potential risks in financial markets.
              </p>
              <br />
              <p style={{color:"#000"}}>
                {" "}
                <BoldTag>Broker Selection</BoldTag> -Choosing a broker is crucial. If the follower's broker reaches its maximum open trades limit, it can lead to malfunctions on certain trading platforms. Moreover, if broker servers are unresponsive, trades may not execute at all. It's important to note that each broker has its own maximum trades per minute and execution speed, adding another layer of complexity to the decision-making process.
              </p>
              <br />
              <p style={{color:"#000"}}>
                {" "}
                <BoldTag>DISCLAIMER</BoldTag><br/> The content on this website, including opinions, news, research, analyses, prices, and other information, is offered as general market commentary and should not be considered investment advice. We do not take responsibility for any losses incurred from investments made based on recommendations, forecasts, or other information provided here.<br/><br/>
                <BoldTag>Happyness Pro is not liable to any losses occurred due to any reason whatsoever due to our platform.</BoldTag>
              </p>
              <br/>
            </>
          }
          imageSrc={coursedetail}
          subheading=" Profits Depends on Conviction: Master Risks to Realise Profits"
        />
        {/* <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={true}
          imageSrc={gols}
          steps={[
            {
              mainIcon: "",
              heading: "Are a Study Abroad Aspirant",
              description:
                "Germany is the Top Destination for Graduation and Post Graduation.",
            },

            {
              mainIcon: "",
              heading: "Want to Learn Basics of German",
              description:
                "Take the First Step to German Proficiency, Open the World of Opportunities.",
            },

            {
              mainIcon: "",
              heading: "Wish to Get Global Certification",
              description:
                "Get an Official and Internationally recognized Goethe Certificate.",
            },
          ]}
        /> */}
{/* 
        <Faqs
          textOnLeft={false}
          buttonUrl="https://wa.me/918920403902/?text=Hi, I need more info How to deploy ..."
          buttonText="Lets Connect"
          description="Here are some frequently asked questions about Goethe-Zertifikat A1: Fit in Deutsch 1 Exam, feel free to reach out in case of we missed out something."
          faqs={[
            {
              question:
                "What are the Eligibility / Requirements of this Exam ??",
              answer:
                "<p>The Goethe-Zertifikat A1: Fit in Deutsch 1 is a German exam for children and young people between 10 and 16 years of age.</p><p>The Goethe-Institut exams are available to all interested parties and can be taken regardless of age and nationality.</p><p>To sit the Goethe-Zertifikat A1: Fit in Deutsch 1 exam, candidates must have German language skills corresponding to the first level of competence (A1) of the Common European Framework of Reference for Languages (CFR).</p>",
            },
            {
              question: "How is Speaking Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to introduce yourself, ask questions on everyday matters and answer simple questions. You will also be required to make requests and respond to your conversation partner in situations with which you are familiar.</p><p><b>Duration of the Speaking Ability Test is 15 Mins</b></p>",
            },
            {
              question: "How is Listening Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to listen to short everyday conversations, personal telephone messages, information on the radio and similar recordings and complete various exercises on what you have heard.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Reading Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to read texts such as brief notes, classified advertisements, descriptions of people, simple letters to the editor or short newspaper articles and complete exercises on these texts.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Writing Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to reply to an email, letter, advertisement or similar piece of correspondence.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
          ]}
        /> */}
        {/* <Testimonials
          subheading="Learn German from the Best Institute in India"
          heading="Our Students Love Us."
          description={
            <>
              <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is
              that we work with you from start to finish. We pride ourselves in
              being fully engaged with your german learning journey, to help you
              open global opportunities for education and career growth.
            </>
          }
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
          textOnLeft={true}
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
              heading:
                "Didn’t Expected Language Learning can be So Interesting & Engaging",
              quote:
                "My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
              customerName: "Showick Thorpe",
              customerTitle: "Founder, V&S Thorpes Education Inc.",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
              heading: "Love the Teaching Experience and Technology Tools",
              quote:
                "I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
              customerName: "Amit Chawla",
              customerTitle: "FCA, Content Creator at EY",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
              heading: "Individualized Attention & Effective Learning",
              quote:
                "We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
              customerName: "Achal Gupta",
              customerTitle: "CEO, Kiran Prakashan",
            },
          ]}
        /> */}
        {/* <FastestGrowing /> */}
        <PreFooter
          fromPage="Strategy Deploy"
          formheading="Kickstart your journey with rule based trading here."
          formsubheading="Book Our Free Discussion Session Today - "
          heightlightText="The Benefits of Using a Structured Content Distribution Platform"
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
