import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/RuleBasedStrategy.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import NiftyIntradayTable from "components/table/NiftyIntradayTable";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import trading2 from "images/trading2.png";
import ideal from "images/ideal.png";
import basicstrategy from "images/basicstrategy.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 font-black`;
const Title = tw.h2`font-bold text-4xl mt-8 md:mt-0 lg:mt-0 md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const Gtext = tw.span`text-blue-900 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  roundedHeaderButton,
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {

  return (
    <>
      <Helmet
        title="Happyness Pro - Learn More About Strategies"
        meta={[
          {
            name: "description",
            content:
              "Rule-Based Trading (systematic strategy) sets clear rules to guide your trades.",
          },
          {
            property: `og:title`,
            content: "Happyness Pro - Your Trading Partner",
          },
          {
            property: `og:description`,
            content: "HappyNess Pro comes into picture, and help you generate reasonable second income.",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/hp_logo.png",
          },
          {
            property: `og:image:alt`,
            content: "HappyNess Pro About Rule Based  Strategies",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"http://happyness.pro/about-rule-based-algo-trading",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />
      <AnimationRevealPage>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <NiftyIntradayTable/>
        <PreFooter
          fromPage="About Strategy"
          formheading="Kickstart your journey with rule based trading here."
          formsubheading="Book Our Free Discussion Session Today - "
          heightlightText="The Benefits of Using a Structured Content Distribution Platform"
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
