import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/RuleBasedStrategy.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import trading2 from "images/trading2.png";
import ideal from "images/ideal.png";
import basicstrategy from "images/basicstrategy.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-blue-900`;
const HighlightedText = tw.span`text-blue-900 font-black`;
const Title = tw.h2`font-bold text-4xl mt-8 md:mt-0 lg:mt-0 md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const Gtext = tw.span`text-blue-900 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {

  return (
    <>
      <Helmet
        title="Happyness Pro - Learn More About Strategies"
        meta={[
          {
            name: "description",
            content:
              "Rule-Based Trading (systematic strategy) sets clear rules to guide your trades.",
          },
          {
            property: `og:title`,
            content: "Happyness Pro - Your Trading Partner",
          },
          {
            property: `og:description`,
            content: "HappyNess Pro comes into picture, and help you generate reasonable second income.",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/hp_logo.png",
          },
          {
            property: `og:image:alt`,
            content: "HappyNess Pro About Rule Based  Strategies",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"http://happyness.pro/about-rule-based-algo-trading",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />

      <AnimationRevealPage>
        <MainBanner
          roundedHeaderButton={true}
          mainTitle={
            <Title>
              <Gtext>What is a STRATEGY (Rules for Trading)</Gtext>
            </Title>
          }
          subTitle="Rule-Based Trading (systematic strategy) sets clear rules to guide your trades, like a recipe. It removes emotions and allows automation, but requires effort, time and expertise to develop, test and maintain effectively. <br/><br/>This is where HappyNess Pro comes into picture, and help you generate reasonable second income.<br/><br/>
          Discuss with our experts today !"
          buttonText="Let's Talk"
          buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to know more About Rule Based Trading"
          imageSrc={trading2}
          
          showClient={false}
          text="About Strategy"
          knowmoreUrl={innerPages.aboutfitindeutsch1.url}
        />
        <TrackRecord
          textOnLeft={false}
          heading={
            <>
              Basics About {" "}
              <HighlightedText>Strategy</HighlightedText>
            </>
          }
          description={
            <>
              <p style={{color:"#000"}}>
                {" "}
                Just like you wouldn't buy a car without considering factors like resale value, mileage, and ownership costs, you shouldn't deploy a <BoldTag>trading strategy</BoldTag> without a thorough analysis.<br/><br/>The financial markets can be unforgiving, and transparency is key.  Here's what separates informed traders from those left in the dust:
              </p>
              <br />
              <p style={{color:"#000"}}>
                <BoldTag>1. Understanding Key Parameters:</BoldTag> Minimum capital requirements, maximum historical drawdown (peak-to-trough decline), and profit factor (a measure of profitability) are crucial data points.<br/><br/>
                <BoldTag>2. Scrutinizing Strategy Mechanics: </BoldTag> Delve into the core concept (technical analysis, price patterns, etc.) and ensure clear entry and exit rules are defined.<br/><br/>
                <BoldTag>3. Demanding Proof of Profitability:</BoldTag> Look for a strategy with a demonstrably successful track record through historical data or backtesting.<br/><br/>
                Remember, a well-prepared trader is a confident trader. Don't let your hard-earned money become a gamble. Analyze strategies with the same rigor you'd apply to any major purchase.
              </p>
              <br />
              {/* <BoldTag>Exam Pattern of About Strategy</BoldTag>
              <p>
                The Goethe-Zertifikat A1: About Strategy exam comprises of 4
                sections -{" "}
                <HighlightedText>
                  reading, listening, writing and speaking
                </HighlightedText>{" "}
                sections. The speaking section of the exam is completed in a
                group. The exam is{" "}
                <BoldTag>
                  administered and evaluated in the same way all over the world
                </BoldTag>
                .
              </p> */}
            </>
          }
          imageSrc={basicstrategy}
          subheading="Steps for Analysing a Strategy Before Deployment"
        />
        <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={true}
          imageSrc={ideal}
          steps={[
            {
              mainIcon: "",
              heading: "Understand the Market Risks - ",
              description:
                "Should be mentally prepared for Market Unexpected Behaviour.",
            },

            {
              mainIcon: "",
              heading: "Are Looking for a Second Income -",
              description:
                "Algo Trading can be deployed on Small Amounts.",
            },

            {
              mainIcon: "",
              heading: "Have Patience & Conviction -",
              description:
                " Both the qualities are the result of confidence and knowledge of Algo Trading and its Basics",
            },
          ]}
        />

        <Faqs
          textOnLeft={false}
          buttonUrl="https://wa.me/918920403902/?text=Hi, I want to know more about Rule Based Trading"
          buttonText="Lets Connect"
          description="Selecting the perfect algo for your trading needs is crucial. Here are some common questions you may have - "
          faqs={[
            {
              question:
                "Q1. How well does the Algo fit my trading style / temperament?",
              answer:
                "<p>Your trading goals and risk tolerance should guide your algo selection. A complex algo might not be ideal if your are new to trading. Therefore, Before choosing an algo, understand the strategies’ trading approach and risk appetite.</p>",
            },
            {
              question: "Q2. Does the Algo have a proven track record?",
              answer:
                "<p> Backtesting and PaperTrading allows you to see how the algo performed historically with real market data. This can help you build you confidence on the algo before risking real capital. Because, in algo, consistency is the key which usually fails without conviction.</p>",
            },
            {
              question: "Q3. Can I understand how the Algo works?",
              answer:
                "<p> Transparency is key. If you don't understand the algo's decision-making process, it'll be difficult to trust its signals and stick to its trade. Therefore, Look for algos services with clear discussions on algo trading styles, drawdowns and backtesting results.</p>",
            },
            {
              question: "Q4. Can the Algo adapt to changing market conditions?",
              answer:
                "<p>Markets are fluid and what works today might not work tomorrow. This is the point where a professional service like ours come into play, and takes the responsibility for  customization and ongoing maintenance to ensure it stays effective as market conditions evolve.</p>",
            },
            {
              question: "Q5. What level of technical knowledge do I need to use a rule-based algorithmic trading service?",
              answer:
                "<p>While some services may require advanced programming skills, many platforms offer intuitive interfaces that allow users to create and deploy trading strategies without extensive coding knowledge. Basic understanding of financial markets and trading concepts is beneficial.</p><li>What risk management features should I look for in a rule-based algorithmic trading service?</li><li>Seek services that provide options for setting stop-loss orders, position sizing based on risk tolerance, portfolio diversification tools, and real-time monitoring capabilities to help mitigate potential risks associated with automated trading.</li>",
            },
          ]}
        />
        {/* <Testimonials
          subheading="Learn German from the Best Institute in India"
          heading="Our Students Love Us."
          description={
            <>
              <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is
              that we work with you from start to finish. We pride ourselves in
              being fully engaged with your german learning journey, to help you
              open global opportunities for education and career growth.
            </>
          }
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
          textOnLeft={true}
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
              heading:
                "Didn’t Expected Language Learning can be So Interesting & Engaging",
              quote:
                "My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
              customerName: "Showick Thorpe",
              customerTitle: "Founder, V&S Thorpes Education Inc.",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
              heading: "Love the Teaching Experience and Technology Tools",
              quote:
                "I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
              customerName: "Amit Chawla",
              customerTitle: "FCA, Content Creator at EY",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
              heading: "Individualized Attention & Effective Learning",
              quote:
                "We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
              customerName: "Achal Gupta",
              customerTitle: "CEO, Kiran Prakashan",
            },
          ]}
        /> */}
        <FastestGrowing />
        <PreFooter
          fromPage="About Strategy"
          formheading="Kickstart your journey with rule based trading here."
          formsubheading="Book Our Free Discussion Session Today - "
          heightlightText="The Benefits of Using a Structured Content Distribution Platform"
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
