import React, { useState } from "react";
import { motion } from "framer-motion";
import Header from "../headers/light.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as ArrowForward } from "images/arrowForward.svg";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import upcoming from "images/upcoming batch.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-8 lg:py-10`;

const TwoColumn = tw.div`flex flex-col lg:flex-row`;
const Column = styled.div(props => [
  tw`mt-16 md:mt-0 xs:px-4`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:order-last`
]);
const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);
// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
//   props.imageShadow ? tw`shadow` : tw`shadow-none`,
//   tw`hidden lg:block rounded h-144 bg-center`
// ]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  
]);

const FAQContent = tw.div`sm:order-last`;
const Subheading = tw(SubheadingBase)`mb-4  lg:text-left`;
const Heading = tw(SectionHeading)`text-left`;
const Description = tw.p` lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-black`;

const FAQSContainer = tw.dl`mt-12 `;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-brand-700 text-gray-100 p-1 rounded-full group-hover:bg-brand-700 group-hover:text-white transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0 mt-10`}
  button {
    ${tw`w-8/12 bg-secondary-600 text-gray-100 xs:m-auto lg:ml-0 lg:mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-secondary-900 transition duration-300`}
  }
`;
const IconContainer = tw.i``;
const ChatButton= tw.button`bg-green-500 text-white px-2 rounded-sm py-1`;
const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`bg-blue-900 hover:bg-brand-700 hover:text-white mt-12 text-sm inline-block mx-auto md:mx-0 rounded-full text-white p-2 px-5`,
   props.buttonRounded && tw`rounded-full`
]);

export default ({
  roundedHeaderButton,
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Here are some frequently asked questions about our Aurum Platform from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc,
  imageContain = false,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  decoratorBlobCss = null,
  faqs = null,
  textOnLeft=false,
  buttonUrl, 
  buttonText,
  buttonRounded=false,
  showHeader=true
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Do We Need a Tech Team to Use Aurum ??",
      answer:
        "Yes, you can create and organize the content without writing code. Building websites, apps and other experiences require Actual Programming, though it can also be managed by Aurum Apps. <p>Aurum is a platform whose interface was designed so that everyone, with or without a technical background, can use it. If you use Aurum without writing code, you can add and edit content types, and also add and manage actual content and Deliver through Aurum Apps and Platform.</p><p>To deliver or display your content outside of Aurum, you will need to use Aurum APIs and create code or work with an agency, freelancer or someone who can develop a platform for you. They can help you create a website, a mobile app and many other experiences based on your content. Needless to say, your content will be shared between all of the platforms so that you only need to create and manage it once. </p><div class='btnblock'><button class='chatbtn'>Chat Now</button> <button class='enrollbtn'>Enroll Now</button></div>"
    },
    {
      question: "What kind of content can I manage?",
      answer:
        "Aurum is built to deliver practically all Kinds of Contents. Be it Video, Live Streaming, Texts, PDFs, HTMLs, Assessments or we can also build one for you. Basically Anything can be put in here, structured and delivered with complete security, scale and access controls."
    },
    {
      question: "What is Aurum Platform ?",
      answer:
        "Aurum Platform is basically a Content Infrastructure. Aurum lets you Create, Assemble, manage, distribute, secure, stream and deliver on almost all devices, online and offline mode. <p>We believe every content requires a different framework to deliver excellent digital experiences. Hence, Aurum lets create your own content model so you can decide which content you want to manage.</p><p>With an uncluttered user interface, Aurum Platform is an efficient tool for creating and managing your content online, either alone or in team. </p>"
    },
    {
      question: "What is approx cost of Aurum Platform ?",
      answer:
        "Aurum Platform is build with Simple Pricing - Pay As You Go. There is no fixed commitment, you simply pay for pricing per GB for Video storage and streaming and per active user for other types of content delivery. <p>If you have large number of users, pricing will be worked out as there would be dedicated resources allocated for your enterprise.</p>"
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <>
      {showHeader &&
      <Header roundedHeaderButton={roundedHeaderButton} />
      }
      <Container>
        <Content>
          <TwoColumn>
            
            <Column  tw="w-full">
              <FAQContent tw="">
                {subheading ? <Subheading>{subheading}</Subheading> : null}
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <div className="upcoming_seminar">
                  <h3><b>*Scheduled On - 18th May 2024, Saturday, 12:00 PM Onwards*</b></h3>
                  <p>The session will be of 75 Mins with Q&A Session from the Attendees.</p>
                </div>

                <div className="google_form">
                  <h3><b>*Steps to Join the Seminar</b></h3>
                  <p>Fill in the Google Forms at the link - <a href=" https://bit.ly/49P2vfi" target="_blank"> https://bit.ly/49P2vfi</a> and our team will get in touch with you shortly.</p>
                </div>
                {/* <FAQSContainer>
                  {faqs.map((faq, index) => (
                    <FAQ
                      key={
                        <>
                        index
                        </>
                      }
                      onClick={() => {
                        toggleQuestion(index);
                      }}
                      className="group"
                    >
                      <Question>
                        <QuestionText>{faq.question}</QuestionText>
                        <QuestionToggleIcon>
                          {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                        </QuestionToggleIcon>
                      </Question>
                      <Answer
                        variants={{
                          open: { opacity: 1, height: "auto", marginTop: "16px" },
                          collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                        }}
                        initial="collapsed"
                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                        dangerouslySetInnerHTML={{ __html:faq.answer}}
                      >
                      </Answer>
                    </FAQ>
                  ))}
                </FAQSContainer> */}
                {buttonUrl &&(
                  <LinkButton buttonRounded={buttonRounded} className="exploreBtn" as="a" href={buttonUrl}>
                    {buttonText} <IconContainer><ArrowForward className="btnArrow" /></IconContainer>
                  </LinkButton>
                )}
              </FAQContent>
            </Column>
            <Column tw="w-full md:w-1/2 flex-shrink-0 mx-auto order-first lg:order-last mt-20 md:mt-24   lg:mt-0">
              <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
            {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
            </Column>
          </TwoColumn>
        </Content>
      </Container>
    </>
  );
};
